import Vue from "vue";
import App from "./layouts/App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./styles/styles.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "floating-vue/dist/style.css";
import FloatingVue from "floating-vue";
import "bootstrap";
import * as VueGoogleMaps from "vue2-google-maps";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBWWSMMZ5hS7VErNuNnFJig7vEcH1Ahtnk",
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(FloatingVue);
Vue.use(VueToast);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueQuillEditor, {
  placeholder: "Dodaj treść...",
  modules: {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["emoji"],
        [{ align: [] }],
      ],
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
