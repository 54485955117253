import VueRouter from "vue-router";
import Vue from "vue";
import axios from "../axios";
import Login from "../pages/Login";
import Users from "../pages/Users";
import Jobs from "../pages/Jobs";
import Payments from "../pages/Payments";
import UsersChats from "../pages/UsersChats";
import Transactions from "../pages/Transactions";
import Reports from "../pages/Reports";
import PaymentProcessing from "../pages/PaymentProcessing";
import Notifications from "../pages/NotificationsForAcceptance";
import SqlReports from "../pages/SqlReports";
import Attractions from "../pages/Attractions";
import AttractionReports from "../pages/AttractionReports";
import Contractors from "@/pages/Contractors";
import DiscountCodes from "@/pages/DiscountCodes";
import UploadAdvertisements from "@/pages/UploadAdvertisements";
import AdvertisementsForApproval from "@/pages/AdvertisementsForApproval";
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/users/:userId?",
    name: "Users",
    component: Users,
  },
  {
    path: "/zlecenia/:userId?",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/wyplaty",
    name: "Payments",
    component: Payments,
  },
  {
    path: "/czaty",
    name: "UserChats",
    component: UsersChats,
  },
  {
    path: "/transakcje/:userId?",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/zgloszenia",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/powiadomienia",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/atrakcje",
    name: "Attractions",
    component: Attractions,
  },
  {
    path: "/atrakcje/zgloszenia",
    name: "AttractionReports",
    component: AttractionReports,
  },
  {
    path: "/payment-processing",
    name: "PaymentProcessing",
    component: PaymentProcessing,
  },
  {
    path: "/reports",
    name: "SqlReports",
    component: SqlReports,
  },
  {
    path: "/dodawanie-zlecen",
    name: "UploadAdvertisements",
    component: UploadAdvertisements,
  },

  {
    path: "/contractors",
    name: "Contractors",
    component: Contractors,
  },
  {
    path: "/discounts",
    name: "DiscountCodes",
    component: DiscountCodes,
  },
  {
    path: "/zlecenia-do-zaakceptowania",
    name: "AdvertisementsForApproval",
    component: AdvertisementsForApproval,
  },
  // {
  //   path: "/chats/:userId",
  //   name: "Chats",
  //   component: () => import("../pages/Chats.vue"),
  //   props: true,
  //   children: [
  //     {
  //       path: ":chatId",
  //       name: "ChatContent",
  //       component: () => import("../pages/ChatContent.vue"),
  //       props: true,
  //     },
  //   ],
  // },

  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   component: () => import("../pages/Notifications.vue"),
  // },
  // {
  //   path: "/notifications/create",
  //   name: "CreateNotification",
  //   component: () => import("../pages/CreateNotification.vue"),
  // },
  // {
  //   path: "/notifications/createAtStart",
  //   name: "CreateNotificationAtStart",
  //   component: () => import("../pages/CreateNotificationAtStart.vue"),
  // },
  // {
  //   path: "/documents",
  //   name: "Documents",
  //   component: () => import("../pages/Documents.vue"),
  // },
  // {
  //   path: "/documents/create",
  //   name: "AddDocument",
  //   component: () => import("../pages/AddDocuments.vue"),
  // },
  // {
  //   path: "/documents/edit/:documentId",
  //   name: "EditDocument",
  //   component: () => import("../pages/EditDocument.vue"),
  //   props: true,
  // },
];
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/admin" : "",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "PaymentProcessing") {
    const token = window.localStorage.getItem("FUCHA_TOKEN");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (to.name !== "Login" && !token) next({ name: "Login" });
    else next();
    next();
  } else {
    next();
  }
});

export default router;
