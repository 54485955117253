import axios from '../../axios.js';

const fetchDocuments = async ({ commit }, data) => {
  const perPage = data.perPage ? `onPage=${data.perPage}&` : '';
  const pageNumber = data.pageNumber ? `pageNumber=${data.pageNumber}&` : '';
  const response = await axios.get(`api/lpDocuments?${perPage}${pageNumber}`);
  commit('SET_DOCUMENTS', response.data.data);
};

const addDocument = async (_, data) => {
  axios.post('/api/lpDocuments', data);
};

export default {
  fetchDocuments,
  addDocument,
};
