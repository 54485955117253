import axios from "axios";
import store from "./store/index";

// export const URL = "http://213.136.79.78:3070/api/";
export const URL = "https://app.fucha-aplikacja.pl/api/";
// http://77.55.236.171:9090
// http://localhost:9090
const instance = axios.create({
  baseURL: URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      store.dispatch("logout");
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default instance;
