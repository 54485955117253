const state = {
  notifications: [],
  confirmations: [],
};

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
};

const getters = {
  getAllNotifications: (state) => state.notifications,
};

export default {
  state,
  mutations,
  getters,
};
