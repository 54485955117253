import axios from "../../axios.js";

const fetchDiscounts = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const search =
    data.search || data.searchUser
      ? `search=${data.search || data.searchUser}`
      : "";

  const response = await axios.get(
    `discountCodes?${perPage}${pageNumber}${search}`
  );
  commit("SET_DISCOUNTS", response.data.data);
  commit("SET_DISCOUNTS_META", response.data.meta);
};

const addDiscountCode = async (_, body) => {
  const res = await axios.post(`discountCodes`, body);
  return res;
};

const deleteDiscountCode = async (_, id) => {
  await axios.delete(`discountCodes/${id}`);
};

export default {
  fetchDiscounts,
  addDiscountCode,
  deleteDiscountCode,
};
