<template>
  <div class="container table-responsive">
    <div
      v-bind:class="showModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="showModal"
        style="position: relative"
        class="modal-wrapper"
        :class="`${showModal ? 'user-modal' : ''}`"
      >
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
          "
          @click="handleCancel($event)"
        >
          <span>x</span>
        </div>
        <div
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4>Szczegóły zlecenia</h4>
          <div class="modal-content">
            <strong>Tytuł zlecenia:</strong>
            <p class="info">{{ currentJob.advertisementTitle || "-" }}</p>
            <strong>Data utworzenia:</strong>
            <p class="info">
              {{
                currentJob.offerCreatedAt
                  ? modifyDate(currentJob.offerCreatedAt)
                  : "-"
              }}
            </p>
            <strong>Data ostatniej modyfikacji:</strong>
            <p class="info">
              {{
                currentJob.timeOfLastActivity
                  ? modifyDate(currentJob.timeOfLastActivity)
                  : "-"
              }}
            </p>
            <strong>Adres:</strong>
            <p class="info">
              {{
                currentJob.advertisementStreet
                  ? `${currentJob.advertisementStreet || ""} ${
                      currentJob.advertisementZipCode || ""
                    } ${currentJob.advertisementCity || ""}`
                  : "-"
              }}
            </p>
            <strong>Status:</strong>
            <p class="info">
              {{
                currentJob.offerStatus
                  ? jobStatuses[currentJob.offerStatus]
                  : "-"
              }}
            </p>
            <strong>Kwota:</strong>
            <p class="info">{{ currentJob.remuneration || "-" }}</p>
            <strong>Opis zlecenia:</strong>
            <p class="info">{{ currentJob.description || "-" }}</p>
            <strong>Pozostały czas zlecenia:</strong>
            <p class="info">
              {{
                !currentJob.expirationTimeHours &&
                !currentJob.expirationTimeMinutes
                  ? "-"
                  : `${
                      currentJob.expirationTimeHours
                        ? `${currentJob.expirationTimeHours}g`
                        : ""
                    } ${currentJob.expirationTimeMinutes || 0}m`
              }}
            </p>

            <strong>Termin realizacji:</strong>
            <p class="info">
              {{
                currentJob.offerCompletionDate
                  ? this.modifyDate(currentJob.offerCompletionDate)
                  : "-"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="h1 fw-light mb-2 mb-sm-4">Czaty użytkowników</p>
    <div class="filter-wrapper">
      <h2>Filtr użytkowników</h2>
      <div class="filter-actions-wrapper">
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="chats && chats.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="chat in chats" :key="chat.id">
          <td>
            {{ chat.ordererUserFullName || "-" }}
            <br />
            <b-button
              v-if="
                chat.ordererUserFullName &&
                chat.ordererUserFullName !== 'Użytkownik usunięty' &&
                chat.ordererUserFullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: chat.ordererUserPhone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>
            {{ chat.contractorUserFullName || "-" }}
            <br />
            <b-button
              v-if="
                chat.contractorUserFullName &&
                chat.contractorUserFullName !== 'Użytkownik usunięty' &&
                chat.contractorUserFullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: chat.contractorUserPhone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td class="link" @click="handleShowJobModal(chat)">
            {{ chat.advertisementTitle }}
          </td>
          <td
            class="link"
            @click="
              handleShowModal(
                chat.contractorUserId,
                chat.messages,
                chat.offerCreatedAt
              )
            "
          >
            {{ "Pokaż wiadomości" }}
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak chatów'"></empty-list-alert>
    <chat-content
      v-if="openModal"
      :user-id="
        currentChat.messages.length > 0
          ? currentChat.messages[0].authorUser.id
          : ''
      "
      :messages="reverseChats(currentChat.messages)"
      :chat-creation="currentChat.createdAt"
      @closeModal="openModal = false"
    >
    </chat-content>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchAllUserChats`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import ChatContent from "./ChatContent.vue";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { GENERAL_STATUSES } from "../utils/statuses";
import { parseDate } from "../utils/helpers";

export default {
  components: { Pagination, ChatContent, EmptyListAlert },
  name: "UsersChats",
  data: () => {
    return {
      columns: [
        "Osoba wysyłająca",
        "Osoba odbierająca",
        "Zlecenie",
        "Wiadomości",
      ],
      checkedValues: [],
      resetMeta: false,
      actionId: "",
      openModal: false,
      modalAction: "",
      currentChat: {},
      searchUser: "",
      currentJob: "",
      showModal: false,
      jobStatuses: { ...GENERAL_STATUSES },
      modifyDate: parseDate,
    };
  },

  methods: {
    ...mapActions(["fetchAllUserChats"]),
    async handleFetch() {
      try {
        await this.fetchAllUserChats({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          searchUser: this.searchUser,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "UserChats",
        query: {
          searchUser: this.searchUser,
        },
      });
      await this.handleFetch();
    },
    handleReset() {
      this.searchUser = "";
      this.$router.push({
        name: "UserChats",
      });
      this.handleFetch();
    },
    handleShowModal(userId, messages, createdAt) {
      this.currentChat = { userId, messages, createdAt };
      this.openModal = true;
    },
    handleShowJobModal(advertisement) {
      this.currentJob = advertisement;
      this.showModal = true;
    },
    handleCancel() {
      this.currentJob = "";
      this.showModal = false;
    },
    reverseChats(arr) {
      const temp = JSON.parse(JSON.stringify(arr));
      return temp.reverse();
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },

  created() {
    const { searchUser: querySearchUser } = this.$router.history.current.query;
    try {
      this.fetchAllUserChats({
        searchUser: querySearchUser || "",
        ...this.meta,
      });
      this.searchUser = querySearchUser || "";
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      chats: "getUsersChats",
      meta: "getUsersChatsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style scoped>
.payment-actions {
  display: flex;
  gap: 5px;
}
.payment-actions img {
  cursor: pointer;
}
.filter-actions-wrapper {
  display: flex;
  justify-content: center;
}
</style>
