<template>
  <div class="container table-responsive">
    <p class="h1 fw-light mb-2 mb-sm-4">Transakcje użytkowników</p>
    <div class="filter-wrapper">
      <h2>Filtr użytkowników</h2>
      <div class="filter-actions-wrapper">
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
      <h3 style="margin-top: 20px">Filtr typów transakcji</h3>
      <div class="filter-actions-wrapper" style="margin-top: 20px">
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          v-bind:class="{ active: actionId === '' }"
          @click="handleFetchWithType('')"
          >Wszystkie
        </b-button>
        <b-button
          v-for="(value, key) in transactionStatuses"
          :key="key"
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          v-bind:class="{ active: actionId === key }"
          @click="handleFetchWithType(key)"
          >{{ value }}
        </b-button>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="transactions && transactions.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="transaction in transactions" :key="transaction.id">
          <td>
            {{ transaction.userFullName || "-" }}
            <br />
            <b-button
              v-if="
                transaction.userFullName &&
                transaction.userFullName &&
                transaction.userFullName !== 'Użytkownik usunięty' &&
                transaction.userFullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: transaction.userPhone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>
            {{ transaction.userEmail || "-" }}
          </td>
          <td>
            {{ transaction.userPhone || "-" }}
          </td>
          <td>
            {{ transaction.userWalletAmount || "-" }}
          </td>
          <td>
            {{ transaction.amount ? Math.abs(transaction.amount) : "-" }}
          </td>
          <td>
            {{ transaction.type ? transactionStatuses[transaction.type] : "-" }}
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak transakcji'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchUserTransactions`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="searchUser"
      :type="this.actionId"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { TRANSACTION_STATUSES } from "../utils/statuses";

export default {
  components: { Pagination, EmptyListAlert },
  name: "Transactions",
  data: () => {
    return {
      columns: [
        "Imie i nazwisko",
        "Email",
        "Numer Telefonu",
        "Stan portfela",
        "Kwota transakcji",
        "Typ",
      ],
      resetMeta: false,
      actionId: "",
      openModal: false,
      modalAction: "",
      searchUser: "",
      transactionStatuses: { ...TRANSACTION_STATUSES },
    };
  },

  methods: {
    ...mapActions(["fetchUserTransactions"]),
    async handleFetch() {
      try {
        await this.fetchUserTransactions({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          searchUser: this.searchUser,
          type: this.actionId,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie odnaleziono transakcji`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "Transactions",
        query: {
          searchUser: this.searchUser,
          type: this.actionId,
        },
      });
      await this.handleFetch();
    },
    async handleFetchWithType(type) {
      this.actionId = type;
      this.handleUsersFilter();
      //await this.handleFetch();
    },
    handleReset() {
      this.searchUser = "";
      this.actionId = "";
      this.$router.push({
        name: "Transactions",
      });
      this.handleFetch();
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },

  async created() {
    const { type: queryStatus, searchUser: querySearchUser } =
      this.$router.history.current.query;
    try {
      await this.fetchUserTransactions({
        per_page: 10,
        current_page: this.meta.current_page || this.$route.query.page || 1,
        searchUser: this.$route.params.userId || querySearchUser || "",
        type: queryStatus || "",
      });
      this.searchUser = querySearchUser || "";
      this.actionId = queryStatus || "";
      if (this.$route.params.userId && this.transactions.length > 0) {
        this.searchUser = this.transactions[0].userFullName;
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie odnaleziono transakcji`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      transactions: "getAllUserTransactions",
      meta: "getPaymentsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style scoped>
.payment-actions {
  display: flex;
  gap: 5px;
}
.payment-actions img {
  cursor: pointer;
}
.filter-actions-wrapper {
  display: flex;
  justify-content: center;
}
</style>
