<template>
  <div class="container table-responsive">
    <!-- ADDING MODAL -->
    <div
      v-bind:class="newContractorModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="newContractorModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${newContractorModal ? 'user-modal' : ''}`"
      >
        <div class="delete-button" @click="cancelnewContractorModal()">
          <span>x</span>
        </div>
        <div class="modal-common-content">
          <h4>Edycja ogłoszenia</h4>
          <div class="modal-content">
            <div class="notification-input-row">
              <label>Nazwa</label>
              <input v-model="name" maxlength="255" type="text" />

              <label>Opis</label>
              <textarea v-model="description" maxlength="8000" />

              <label>Kategoria</label>
              <select v-model="categoryId">
                <option :value="cat.id" v-for="cat in categories" :key="cat.id">
                  {{ cat.title }}
                </option>
              </select>
              <br />
              <div style="display: flex; justify-content: center">
                <label>Adres:</label>
                <div class="" v-if="!changeAddress && currentContractor !== ''">
                  {{
                    `${place.city} ${place.street || ""} ${
                      place.address || ""
                    } ${place.zipCode || ""} `
                  }}
                  <span
                    @click="(changeAddress = true), (place = {})"
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      font-weight: 800;
                    "
                    >Zmień adres</span
                  >
                </div>
                <GSearch v-else @newPlace="handleNewPlace"></GSearch>
              </div>

              <br />
              <b-button
                style="font-weight: 700; width: 100%; margin-bottom: 10px"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                id="filter-button"
                @click="saveNewContractor"
              >
                {{
                  currentContractor !== ""
                    ? "Zapisz zmiany"
                    : "Dodaj ogłoszenie"
                }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE CONFIRM MODAL -->
    <div
      v-bind:class="showModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="showModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${'confirmation-modal'}`"
      >
        <div class="delete-button" @click="handleCancel()">
          <span>x</span>
        </div>
        <div class="modal-common-content">
          <h4>Czy na pewno chcesz usunąć ogłoszenie?</h4>
          <div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                margin: 40px auto;
              "
            >
              <b-button
                style="width: 300px; margin-left: 10px; font-weight: 700"
                class="btn btn-sm ml-1"
                variant="outline-secondary"
                id="filter-button"
                @click="handleDeleteContractor"
                >Tak
              </b-button>
              <b-button
                style="width: 300px; margin-left: 10px; font-weight: 700"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                @click="handleCancel"
                >Nie
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="h1 fw-light mb-2 mb-sm-4">Lista ogłoszeń</p>
    <div class="filter-wrapper">
      <div class="filter-actions-wrapper">
        <h2>Filtr po kategorii</h2>
        <div class="filter-buttons">
          <select
            class="filter-select"
            style="min-width: 180px"
            v-model="chosenCategory"
          >
            <option value="" disabled selected>Wybierz kategorie</option>
            <option :value="cat.name" v-for="cat in categories" :key="cat.name">
              {{ cat.title }}
            </option>
          </select>
          <b-button
            style="width: 80px; margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            id="filter-button"
            @click="handleContractorsFilter"
            >Filtruj
          </b-button>
          <b-button
            style="margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleReset"
            >Wyczyść filtr
          </b-button>
        </div>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="contractors && contractors.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contract in contractors" :key="contract.id">
          <td style="position: relative">
            <div class="action-column">
              <div @click="handleShowModal(contract.id)" class="danger-button">
                <span>x</span>
                <span class="hint">Usuń ogłoszenie</span>
              </div>
              <div @click="handleStartContractorEdit(contract)">
                <img src="../assets/edit.svg" alt="Edit contractor" />
                <span class="hint">Edytuj ogłoszenie</span>
              </div>
            </div>
          </td>
          <td>{{ contract.name || "" }}</td>

          <td>
            {{ contract.user.fullName || "" }}
            <br />
            <b-button
              v-if="
                contract.user &&
                contract.user.fullName &&
                contract.user.fullName !== 'Użytkownik usunięty' &&
                contract.user.fullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: contract.user.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>
            {{ contract.contractorPhone || "" }}<br />{{
              contract.contractorEmail || ""
            }}
          </td>
          <td>{{ contract.category ? contract.category.title : "" }}</td>
          <td>
            {{ contract.description || "" }}
          </td>
          <td>
            {{ contract.city || "" }}
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak ogłoszeń'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchContractors`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="status"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { parseDate } from "../utils/helpers";
import router from "../router";
import GSearch from "@/components/GSearch";

export default {
  components: { EmptyListAlert, Pagination, GSearch },
  name: "Contractors",
  data: () => {
    return {
      columns: [
        "",
        "Nazwa ogłoszenia",
        "Osoba ogłaszająca",
        "Dane kontaktowe",
        "Kategoria",
        "Opis",
        "Miasto",
      ],
      searchUser: "",
      currentJob: "",
      showModal: false,
      newContractorModal: false,
      changeAddress: false,
      modifyDate: parseDate,
      router: router,
      chosenCategory: "",
      currentContractor: "",
      name: "",
      place: {},
      description: "",
      categoryId: "",
    };
  },
  methods: {
    ...mapActions([
      "fetchContractors",
      "fetchContractorsCategories",
      "deleteContractor",
      "editContractor",
    ]),
    async handleFetch() {
      try {
        await this.fetchContractorsCategories();
        await this.fetchContractors({
          ...this.meta,
          category: this.chosenCategory,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zgłoszeń`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
    //HANDLING FILTERS
    async handleContractorsFilter() {
      this.$router.push({
        name: "Contractors",
        query: {
          category: this.chosenCategory,
        },
      });
      await this.handleFetch();
    },
    //RESETING FILTERS
    handleReset() {
      this.chosenCategory = "";
      this.$router.push({
        name: "Contractors",
      });
      this.handleFetch();
    },
    //DELETING CONFIRMATION MODALS
    handleCancel() {
      this.currentContractor = "";
      this.showModal = false;
    },
    handleShowModal(id) {
      this.currentContractor = id;
      this.showModal = true;
    },

    //DELETE CONTRACTOR METHOD
    async handleDeleteContractor() {
      if (this.currentContractor === "") {
        this.$toast.open({
          message: `Wystąpił błąd, spróbuj ponownie.`,
          type: "error",
        });
      } else {
        try {
          await this.deleteContractor(this.currentContractor);
          this.$toast.open({
            message: `Ogłoszenie zostało usunięte.`,
            type: "success",
          });
          this.handleCancel();
          await this.handleFetch();
        } catch (err) {
          if (err.status === 403) {
            this.$toast.open({
              message: `Operacja zabroniona`,
              type: "error",
            });
          } else if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono zgłoszeń`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      }
    },
    //NEW PLACE FROM AUTOCOMPLETE
    handleNewPlace(place) {
      this.place = place;
    },
    // SETTING UP EDITITNG
    async handleStartContractorEdit(contractor) {
      this.currentContractor = contractor.id;
      try {
        this.name = contractor.name;
        this.description = contractor.description;
        this.categoryId = contractor.category.id;
        this.place = {
          streetNumber: contractor.streetNumber,
          street: contractor.street,
          zipCode: contractor.zipCode,
          city: contractor.city,
          latitude: contractor.lat,
          longitude: contractor.lng,
        };
        this.newContractorModal = true;
      } catch (e) {
        console.log(e);
      }
    },
    cancelnewContractorModal() {
      this.changeAddress = false;
      this.newContractorModal = false;
    },
    //EDIT CONTRACTOR METHOD
    async saveNewContractor() {
      if (
        this.name === "" ||
        this.description === "" ||
        this.categoryId === "" ||
        !this.place.city ||
        !this.place.zipCode ||
        !this.place.latitude ||
        !this.place.longitude
      ) {
        this.$toast.open({
          message: `Uzupełnij wszystkie pola: nazwa, opis, kategoria, adres.`,
          type: "error",
        });
      } else {
        try {
          const body = {
            name: this.name,
            description: this.description,
            categoryId: this.categoryId,
            street: this.place.street || undefined,
            streetNumber: this.place.address || undefined,
            city: this.place.city || undefined,
            zipCode: this.place.zipCode || undefined,
            lng: parseFloat(this.place.longitude) || undefined,
            lat: parseFloat(this.place.latitude) || undefined,
            id: this.currentContractor,
          };

          await this.editContractor(body);
          this.$toast.open({
            message: `Ogłoszenie zostało edytowane pomyślnie.`,
            type: "success",
          });
          this.cancelnewContractorModal();
          this.currentContractor = "";
          this.name = "";
          this.description = "";
          this.categoryId = "";
          this.place = {};
          await this.handleFetch();
        } catch (err) {
          if (err.status === 403) {
            this.$toast.open({
              message: `Operacja zabroniona`,
              type: "error",
            });
          } else if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono zgłoszeń`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      }
    },
  },
  async created() {
    try {
      await this.fetchContractorsCategories();
      await this.fetchContractors(this.meta);
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono zgłoszeń`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },
  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      contractors: "getContractors",
      categories: "getContractorsCategories",
      meta: "getContractorsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/variables.scss";
@import "/src/styles/common-modal.scss";
@import "/src/styles/select.scss";

.filter-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
  .filter-buttons {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}
.notification-input-row {
  width: 100%;
  margin-top: 2rem;
}
.notification-input-row table {
  margin: 0 auto;
}
.notification-input-row label {
  margin-bottom: 2rem;
  width: 30%;
  font-weight: bold;
}
.notification-input-row input,
.notification-input-row textarea {
  width: 50%;
}
</style>
