import axios from "../../axios.js";

const fetchReports = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}` : "";
  try {
    const response = await axios.get(
      `admins/advertisementsReports?${perPage}${pageNumber}${searchUser}`
    );
    commit("SET_REPORTS", response.data.data);
    commit("SET_REPORTS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response?.status };
  }
};

export default {
  fetchReports,
};
