<template>
  <div class="container table-responsive">
    <div
      v-bind:class="
        userModal || showDeleteModal || showArgumentModal || showOffersModal
          ? 'd-flex'
          : 'd-none'
      "
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="
          userModal || showDeleteModal || showArgumentModal || showOffersModal
        "
        style="position: relative"
        class="modal-wrapper"
        :class="`${userModal ? 'user-modal' : ''}`"
      >
        <div
          :style="`
            position: absolute;
            top: 2px;
            right: ${
              showOffersModal && currentOffers.length > 1 ? '20px;' : '2px;'
            }
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
            z-index: 9999;`"
          @click="handleCancel($event)"
        >
          <span>x</span>
        </div>
        <div v-if="userModal" class="modal-content">
          <img
            v-if="userData.avatar"
            :src="userData.avatar"
            alt="User avatar"
            class="avatar-img"
          />
          <img
            v-else
            src="../assets/blank_person.jpg"
            alt="User avatar"
            class="avatar-img"
          />
          <p>
            <span>Imie i nazwisko: </span><span>{{ userData.name }}</span>
          </p>
          <p>
            <span>Adres e-mail: </span><span>{{ userData.email }}</span>
          </p>
          <p>
            <span>Numer telefonu: </span><span>{{ userData.phone }}</span>
          </p>
          <p style="width: 90%; margin-left: auto; margin-right: auto">
            <span>Opis użytkownika: </span
            ><span>{{ userData.description }}</span>
          </p>
          <p>
            <span>Notatka: </span>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              v-model="userData.note"
              style="resize: none; margin: 10px auto; display: block"
            ></textarea>
          </p>
          <b-button
            style="width: fit-content; margin: 10px auto"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleEditNote()"
            >Zapisz
          </b-button>
        </div>
        <div
          v-else-if="showDeleteModal"
          class="modal-content"
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          Czy chcesz usunąć zlecenie?

          <div class="d-flex">
            <b-button
              style="margin-left: 10px"
              class="btn btn-sm ml-1"
              variant="outline-danger"
              @click="handleDelete()"
              >Usuń
            </b-button>
            <b-button
              style="margin-left: 10px"
              class="btn btn-sm ml-1"
              variant="outline-primary"
              @click="handleCancel($event)"
              >Anuluj
            </b-button>
          </div>
        </div>
        <div
          v-else-if="showArgumentModal"
          class="modal-content"
          style="
            padding: 2rem;
            background: white;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 40px 0;
          "
        >
          <h4 style="font-weight: 700">W jaki sposób rozstrzygnąć spór?</h4>
          <div
            class="d-flex"
            style="padding: 20px 20px; gap: 20px; margin: auto"
          >
            <div class="argument-btn-wrapper">
              <b-button
                style="font-size: 18px"
                class="btn btn-sm ml-1"
                variant="outline-danger"
                @click="handleShowArgumentConfirmation('UNREALIZED_CONTRACTOR')"
                >Niezrealizowane z winy zleceniobiorcy
              </b-button>
              <p>Zleceniodawca otrzyma zwrot środków za wystawione zlecenie</p>
            </div>
            <div class="argument-btn-wrapper">
              <b-button
                style="font-size: 18px"
                class="btn btn-sm ml-1"
                variant="outline-danger"
                @click="handleShowArgumentConfirmation('UNREALIZED_ORDERER')"
                >Niezrealizowane z winy zleceniodawcy
              </b-button>
              <p>Zleceniobiorca otrzyma wynagrodzenie za wystawione zlecenie</p>
            </div>
          </div>
          <b-button
            style="font-size: 18px; margin-top: auto"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
        <div
          v-else-if="showOffersModal"
          class="modal-content"
          style="
            padding: 2rem;
            background: white;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4 style="margin-top: 10px">Złożone oferty</h4>
          <div v-for="offer in currentOffers" :key="offer.id" class="offer">
            <p>
              <span>Data utworzenia: </span>
              <span>{{
                offer.createdAt ? offer.createdAt.slice(0, 10) : "-"
              }}</span>
            </p>
            <p>
              <span>Data zakończenia: </span>
              <span>{{
                offer.completionDate ? offer.completionDate.slice(0, 10) : "-"
              }}</span>
            </p>
            <p>
              <span>Wynagrodzenie: </span>
              <span>{{ offer.remuneration || "-" }}</span>
            </p>
            <p>
              <span>Status: </span>
              <span>{{
                offer.status ? generalStatuses[offer.status] : "-"
              }}</span>
            </p>
            <p>
              <span>E-mail użytkownika: </span>
              <span>{{ offer.userEmail || "-" }}</span>
            </p>
            <p>
              <span>Numer telefonu użytkownika: </span>
              <span>{{ offer.userPhone || "-" }}</span>
            </p>
            <p>
              <span class="link" @click="handleFetchChat(offer.chatId)">
                Pokaż czat
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConfirmArgumentModal" class="argument-modal">
      <div style="position: relative">
        <div
          style="
            position: absolute;
            top: 2px;
            right: 2px;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
            z-index: 9999;
          "
          @click="handleCloseArgumentConfirmation()"
        >
          <span>x</span>
        </div>
        <div class="argumnet-modal-content">
          Czy potwierdzasz swoją decyzję?
          <div class="d-flex">
            <b-button
              class="btn btn-sm ml-1"
              variant="outline-primary"
              @click="handleCloseArgumentConfirmation()"
              >Anuluj
            </b-button>
            <b-button
              class="btn btn-sm ml-1"
              variant="outline-danger"
              @click="handleSettleArgument()"
              >Potwierdź
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <p class="h1 fw-light mb-2 mb-sm-4">Lista zleceń</p>
    <div class="filter-wrapper">
      <h2>Filtr użytkowników</h2>
      <div class="filter-actions-wrapper">
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
      <h3 style="margin-top: 20px">Filtr statusów</h3>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        v-bind:class="{ active: status === '' }"
        variant="outline-primary"
        @click="handleFetchWithStatus('')"
        >Wszystkie
      </b-button>
      <b-button
        v-for="(value, key) in jobStatuses"
        :key="key"
        style="margin-left: 10px; font-weight: 700; margin-top: 5px"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: status === `${key}` }"
        @click="handleFetchWithStatus(key)"
        >{{ value }}
      </b-button>
    </div>
    <table
      class="table align-middle"
      v-if="jobs && jobs.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="job in jobs" :key="job.id">
          <td>
            <div
              v-if="status !== 'DELETED'"
              @click="handleShowModal(job.id)"
              class="danger-button"
            >
              <span>x</span>
              <span class="hint">Usuń zlecenie</span>
            </div>
          </td>
          <td>
            {{ job.title || "-" }}
          </td>
          <td>
            <span class="link" @click="showUserModal(job, 'orderer')">{{
              job.orderer && job.orderer.fullName ? job.orderer.fullName : "-"
            }}</span>

            <b-button
              v-if="
                job.orderer.fullName &&
                job.orderer.fullName !== 'Użytkownik usunięty' &&
                job.orderer.fullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: job.orderer.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>
            <span class="link" @click="showUserModal(job, 'contractor')">{{
              job.contractor && job.contractor.fullName
                ? job.contractor.fullName
                : "-"
            }}</span>
            <b-button
              v-if="
                job.contractor &&
                job.contractor.fullName &&
                job.contractor.fullName !== 'Użytkownik usunięty' &&
                job.contractor.fullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: job.contractor.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>
            {{ job.createdAt ? modifyDate(job.createdAt) : "-" }}
          </td>
          <td>
            {{ job.modifiedAt ? modifyDate(job.modifiedAt) : "-" }}
          </td>
          <td>
            {{ formatAddress(job.street, job.address, job.city) }}
          </td>
          <td>
            {{ job.status ? jobStatuses[job.status] : "-" }}
          </td>
          <td>
            {{ job.remuneration || "-" }}
          </td>
          <td v-html="job.description || '-'"></td>
          <td>
            {{
              !job.expirationTimeHours && !job.expirationTimeMinutes
                ? "-"
                : `${
                    job.expirationTimeHours ? `${job.expirationTimeHours}g` : ""
                  } ${job.expirationTimeMinutes || 0}m`
            }}
          </td>
          <td class="actions">
            <span
              v-if="job.status === 'UNREALIZED_WAITING'"
              class="link"
              @click="handleShowArgumentModal(job.id)"
              >Rozstrzygnij spór</span
            >
            <span class="link" @click="handleShowGallery(job.files)"
              >Pokaż Zdjęcia</span
            >
          </td>
          <td class="link" @click="handleShowOffersModal(job.offers)">
            Pokaż oferty
          </td>
          <td>
            {{
              getAcceptedOfferCompletionDateFromAdvertisementOffers(job.offers)
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak zleceń'"></empty-list-alert>
    <gallery
      v-if="showImagesModal"
      :images="imagesToDsiplay"
      @close="handleCloseGallery"
    />
    <chat-content
      v-if="showChatModal"
      :user-id="
        currentChat.messages.length > 0
          ? currentChat.messages[0].authorUserId
          : ''
      "
      :messages="currentChat.messages"
      :chat-creation="currentChat.createdAt"
      style="z-index: 99999"
      @closeModal="showChatModal = false"
    >
    </chat-content>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchJobs`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="status"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import { JOB_STATUSES, GENERAL_STATUSES } from "../utils/statuses";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import Gallery from "../components/Gallery.vue";
import { parseDate } from "../utils/helpers";
import ChatContent from "./ChatContent.vue";

export default {
  components: { Pagination, EmptyListAlert, Gallery, ChatContent },
  name: "Jobs",
  data: () => {
    return {
      columns: [
        "",
        "Tytuł",
        "Zleceniodawca",
        "Zleceniobiorca",
        "Data utworzenia",
        "Data ostatniej modyfikacji",
        "Adres",
        "Status",
        "Kwota",
        "Opis zlecenia",
        "Pozostały czas",
        "Akcje",
        "Złożone oferty",
        "Termin realizacji",
      ],
      status: "",
      searchValue: "",
      searchUser: "",
      maxHeight: 0,
      userData: {},
      jobStatuses: { ...JOB_STATUSES },
      generalStatuses: { ...GENERAL_STATUSES },
      resetMeta: false,
      userModal: false,
      imagesToDsiplay: [],
      showImagesModal: false,
      showDeleteModal: false,
      showOffersModal: false,
      showConfirmArgumentModal: false,
      currentOffers: [],
      userToDeleteId: "",
      showArgumentModal: false,
      showChatModal: false,
      currentChat: {},
      jobId: "",
      argumentStatus: "",
      argumentStatuses: {
        UNREALIZED_ORDERER: "Niezrealizowane (zleceniodawca)",
        UNREALIZED_CONTRACTOR: "Niezrealizowane (zleceniobiorca)",
      },
      modifyDate: parseDate,
    };
  },

  methods: {
    ...mapActions([
      "fetchJobs",
      "fetchUserAvatar",
      "deleteJob",
      "settleArgument",
      "fetchJobChat",
      "editUserNote",
    ]),
    formatAddress(street, buildingNumber, city) {
      if (!city) {
        return "-";
      }
      return `${city} ${
        street ? `${street} ${buildingNumber ? buildingNumber : ""}` : ""
      }`;
    },
    async handleFetch() {
      try {
        await this.fetchJobs({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          searchUser: this.searchUser,
          status: this.status,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie odnaleziono zlecenia`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "Jobs",
        query: {
          status: this.status,
          searchUser: this.searchUser,
        },
      });
      await this.handleFetch();
    },
    async handleReset() {
      this.searchValue = "";
      this.status = "";
      this.showChatModal = false;
      this.$router.push({
        name: "Jobs",
      });
      await this.handleFetch();
    },
    handleDropdown() {
      this.maxHeight === 0 ? (this.maxHeight = 9999) : (this.maxHeight = 0);
    },
    handleFetchWithStatus(status) {
      this.status = status;
      this.handleUsersFilter();
      this.resetMeta = true;
      //this.handleFetch();
      this.resetMeta = false;
    },
    async showUserModal(obj, type) {
      if (obj[type]) {
        let avatar = "";
        try {
          const response = await this.fetchUserAvatar(obj[type].avatar.id);
          if (response) {
            avatar = response;
          }
        } catch (err) {
          if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
        this.userData = {
          id: obj[type].id || "-",
          name: obj[type].fullName || "-",
          phone: obj[type].phone || "-",
          description: obj[type].description || "-",
          email: obj[type].email || "-",
          status: this.jobStatuses[obj[type].status],
          avatar,
          note: obj[type].note || "",
        };
        this.userModal = true;
      } else {
        this.$toast.open({
          message: `Brak danych o użytkowniku`,
          type: "error",
        });
      }
    },
    handleCancel() {
      this.userModal = false;
      this.showDeleteModal = false;
      this.showArgumentModal = false;
      this.showOffersModal = false;
      this.showConfirmArgumentModal = false;
      this.userData = {};
      this.userToDeleteId = "";
      this.jobId = "";
      this.argumentStatus = "";
      this.currentOffers = [];
    },
    async handleShowGallery(images) {
      if (images && images.length && images.length > 0) {
        this.imagesToDsiplay = await Promise.all(
          images.map((e) => {
            if (e.type === "ADVERTISEMENT_IMAGE") {
              return this.fetchUserAvatar(e.id);
            }
          })
        );
        this.showImagesModal = true;
      } else {
        this.$toast.open({
          message: `Galeria jest pusta`,
          type: "error",
        });
      }
    },
    handleCloseGallery() {
      this.showImagesModal = false;
    },
    handleShowModal(userId) {
      this.userToDeleteId = userId;
      this.showDeleteModal = true;
    },
    handleShowOffersModal(offers) {
      if (offers && offers.length > 0) {
        this.currentOffers = [...offers];
        this.showOffersModal = true;
      } else {
        this.$toast.open({
          message: `Dane zlecenie nie posiada ofert`,
          type: "error",
        });
      }
    },
    async handleDelete() {
      try {
        await this.deleteJob({
          id: this.userToDeleteId,
        });
        this.$toast.open({
          message: `Usunięto zlecenie`,
          type: "success",
        });
      } catch (e) {
        if (e.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zlecenia`,
            type: "error",
          });
        } else if (e.status === 403) {
          this.$toast.open({
            message: `Zlecenie musi być aktywne`,
            type: "error",
          });
        } else if (e.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.userToDeleteId = "";
      await this.handleFetch();
      this.showDeleteModal = false;
    },
    handleShowArgumentModal(jobId) {
      this.jobId = jobId;
      this.showArgumentModal = true;
    },
    handleShowArgumentConfirmation(status) {
      this.argumentStatus = status;
      this.showConfirmArgumentModal = true;
    },
    handleCloseArgumentConfirmation() {
      this.argumentStatus = "";
      this.showConfirmArgumentModal = false;
    },
    async handleSettleArgument() {
      try {
        await this.settleArgument({
          id: this.jobId,
          status: this.argumentStatus,
        });
        await this.handleFetch();
        this.handleCancel();
        this.$toast.open({
          message: `Poprawnie rozstrzygnięto spór`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Status zlecenia musi być "Niezrealizowane oczekujące"`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleFetchChat(chatId) {
      if (chatId) {
        try {
          this.currentChat = await this.fetchJobChat({ chatId });
          this.currentChat.messages.reverse();
          this.showChatModal = true;
        } catch (err) {
          if (err.status === 404) {
            this.$toast.open({
              message: `Brak czatu do zlecenia`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      } else {
        this.$toast.open({
          message: `Brak czatu do zlecenia`,
          type: "error",
        });
      }
    },
    getAcceptedOfferCompletionDateFromAdvertisementOffers(offers) {
      const acceptedOffer = offers
        ? offers.find((offer) => offer.status === "ACCEPTED")
        : null;
      return acceptedOffer
        ? this.modifyDate(acceptedOffer.completionDate)
        : "-";
    },
    async handleEditNote() {
      try {
        if (this.userData.note.length < 4001) {
          await this.editUserNote({
            id: this.userData.id,
            note: this.userData.note,
          });
          this.handleCancel();
          await this.handleFetch();
          this.$toast.open({
            message: `Zedytowano notatkę`,
            type: "success",
          });
        } else {
          this.$toast.open({
            message: `Notatka może mieć maksymalnie 4000 znaków`,
            type: "error",
          });
        }
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono użytkownika`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },

  async created() {
    const { status: queryStatus, searchUser: querySearchUser } =
      this.$router.history.current.query;
    try {
      await this.fetchJobs({
        per_page: 10,
        current_page: this.meta.current_page || this.$route.query.page || 1,
        searchUser: this.$route.params.userId || querySearchUser || "",
        status: queryStatus || "",
      });
      this.searchUser = querySearchUser || "";
      this.status = queryStatus || "";
      if (this.$route.params.userId && this.jobs.length > 0) {
        if (
          this.jobs[0].orderer &&
          this.jobs[0].orderer.id === this.$route.params.userId
        ) {
          this.searchUser = this.jobs[0].orderer.fullName;
        } else if (
          this.jobs[0].contractor &&
          this.jobs[0].contractor.id === this.$route.params.userId
        ) {
          this.searchUser = this.jobs[0].contractor.fullName;
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      jobs: "getJobs",
      meta: "getJobsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style scoped>
.user-modal {
  width: 50vw;
  height: 70vh;
  background-color: white;
  border-radius: 15px;
}
.avatar-img {
  width: 45vw;
  height: 35vh;
  object-fit: contain;
  margin: 20px auto 0 auto;
}

.danger-button:hover .hint {
  display: block;
}

td:nth-child(n + 2) {
  max-width: 115px;
  overflow-wrap: break-word;
}

.actions span {
  display: block;
  margin-top: 10px;
}

.offer {
  border-top: 1px solid lightgrey;
  min-width: 30vw;
  margin: 10px auto;
  padding-top: 5px;
}

.offer p {
  text-align: left;
}

.offer p span:nth-child(1) {
  font-weight: 700;
}

.argument-btn-wrapper {
  max-width: 250px;
}

.argument-btn-wrapper p {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 10px;
  word-break: break-word;
  font-weight: 500;
}

.argument-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000020;
  z-index: 100;
  border-radius: 15px;
}

.argumnet-modal-content {
  padding: 2rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
}

.argumnet-modal-content div {
  margin-top: 20px;
  gap: 20px;
}

.argumnet-modal-content div button {
  padding: 10px;
  font-size: 16px;
  width: 100px;
}
</style>
