var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container table-responsive"},[_c('p',{staticClass:"h1 fw-light mb-2 mb-sm-4"},[_vm._v("Lista zgłoszeń atrakcji")]),(_vm.reports && _vm.reports.length > 0)?_c('table',{staticClass:"table align-middle",staticStyle:{"margin-bottom":"30px"}},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column},[_vm._v(" "+_vm._s(column)+" ")])}),0)]),_c('tbody',_vm._l((_vm.reports),function(report){return _c('tr',{key:report.id},[_c('td',[_vm._v(" "+_vm._s(report.reportingUser.fullName || "")+" "),_c('br'),(
              report.reportingUser &&
              report.reportingUser.fullName &&
              report.reportingUser.fullName !== 'Użytkownik usunięty' &&
              report.reportingUser.fullName !== 'Użytkownik nieaktywny'
            )?_c('b-button',{staticClass:"btn btn-sm ml-1",staticStyle:{"margin":"5px 5px 0 0"},attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'Notifications',
                query: { s: report.reportingUser.phone },
                params: { newNotification: '1' },
              })}}},[_vm._v("Wyślij powiadomienie")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(report.description || ""))]),_c('td',[_vm._v(_vm._s(report.createdAt ? report.createdAt.slice(0, 10) : "-"))]),_c('td',{staticClass:"link",on:{"click":function($event){return _vm.router.push(
              ("/atrakcje?searchAttraction=" + (report.reportedAttraction.title))
            )}}},[_vm._v(" "+_vm._s(report.reportedAttraction.title || "")+" ")])])}),0)]):_c('empty-list-alert',{attrs:{"content":'Brak zgłoszeń'}}),(_vm.isDownPaginationVisible)?_c('pagination',{staticClass:"mx-auto",attrs:{"dispatchActionName":"fetchAttractionReports","perPage":_vm.meta.per_page,"pageNumber":_vm.meta.current_page,"total":_vm.meta.total,"status":_vm.status,"search-user":_vm.searchUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }