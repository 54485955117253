<template>
  <div class="container">
    <p class="h1 fw-light mb-2 mb-sm-4">Generowanie raportów</p>

    <textarea
      v-model="sql"
      placeholder="select id, status from advertisements / select created_at, id, email from users order by created_at desc"
      @keypress.enter.prevent="generateReport"
    />
    <div>
      <b-button
        class="btn btn-sm ml-1 generate-button"
        variant="outline-primary"
        @click="generateReport"
        >Generuj raport
      </b-button>

      <b-button
        class="btn btn-sm ml-1 generate-button mt-2"
        variant="outline-primary"
        @click="generateReportXLSX"
        >Pobierz raport
      </b-button>
    </div>

    <table
      class="table align-middle table-response"
      v-if="reportRows && reportRows.length > 0"
    >
      <thead>
        <tr>
          <td v-for="column of reportColumns" :key="column">{{ column }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) of reportRows" :key="row.id || index">
          <td v-for="key in reportColumns" :key="key">{{ row[key] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "../axios";
export default {
  components: {},
  name: "SqlReports",
  data: () => {
    return {
      sql: "",
      reportRows: [],
      reportColumns: [],
    };
  },

  methods: {
    async generateReport() {
      if (!this.sql) {
        this.$toast.open({
          message: `Wprowadź zapytanie SQL`,
          type: "error",
        });

        return;
      }

      try {
        const response = await axios.post(`sqlReports/generate`, {
          sql: this.sql,
        });
        if (response && response.data && response.data[0]) {
          this.reportColumns = Object.keys(response.data[0]);
          this.reportRows = response.data;
        }
        if (response && response.data && response.data.length === 0) {
          this.$toast.open({
            message: `Brak wyników`,
            type: "error",
          });
        }
        if (
          response &&
          response.data &&
          response.data.affectedRows &&
          response.data.affectedRows > 0
        ) {
          this.$toast.open({
            message: `Rekord został pomyślnie dodany`,
            type: "success",
          });
        }
      } catch (error) {
        this.$toast.open({
          message: `Nie udało się wykonać zapytania. Sprawdź czy zapytanie jest na pewno bez żadnych błędów`,
          type: "error",
        });
        this.reportColumns = [];
        this.reportRows = [];
      }
    },

    async generateReportXLSX() {
      try {
        const params = new URLSearchParams();
        params.append("sql", this.sql);
        const url = `/sqlReports/generateXLSX`;
        const response = await axios(url, {
          method: "GET",
          headers: {
            "Content-Disposition": "attachment; filename=raport.xls",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob",
          params,
        });
        if (response) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "raport.xls");
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      } catch (error) {
        this.$toast.open({
          message: `Nie udało się pobrać raportu`,
          type: "error",
        });
      }
    },
  },

  async created() {},

  watch: {
    sql() {
      if (this.sql) {
        const sqlLowerCase = this.sql.toLowerCase();
        const forbiddenKeywords = [
          "alter",
          "delete",
          "update",
          "drop",
          "truncate",
          "grant",
          "revoke",
        ];
        const regex = new RegExp(
          `\\b(${forbiddenKeywords.join("|")})\\b`,
          "gi"
        );

        if (sqlLowerCase.match(regex)) {
          this.$toast.open({
            message: `Zapytanie zawiera niedozwolone słowo kluczowe`,
            type: "error",
          });

          this.sql = this.sql.replace(regex, "");
        }
      }
    },
  },

  computed: {},
};
</script>

<style scoped>
textarea {
  width: 70%;
  text-align: center;
  padding: 1rem;
  min-height: 250px;
}
.generate-button {
  width: 70%;
}
.table-response {
  margin-top: 3rem;
}
</style>
