<template>
  <div class="gallery-blur-wrapper">
    <div v-if="images.length > 0" class="gallery-wrapper">
      <img
        src="../assets/left-arrow.png"
        alt="Left Arrow"
        class="arrow left-arrow"
        @click="handleChangeImage('decrement')"
      />
      <img
        :src="images[currentIndex]"
        alt="Gallery Image"
        class="gallery-iamge"
      />
      <img
        src="../assets/right-arrow.png"
        alt="Right Arrow"
        class="arrow right-arrow"
        @click="handleChangeImage('increment')"
      />
      <span class="close-gallery-button" @click="emitClose">x</span>
    </div>
    <div v-else class="gallery-wrapper">
      <h1>Brak zdjęć w galerii</h1>
      <span class="close-gallery-button" @click="emitClose">x</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: ["images"],
  data: () => {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    handleChangeImage(type) {
      if (type === "increment" && this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else if (type === "decrement" && this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.gallery-blur-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.7);
}

.gallery-wrapper {
  position: relative;
  background-color: white;
  border-radius: 10px;
}

.arrow {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  display: block;
  object-fit: contain;
  cursor: pointer;
}

.left-arrow {
  left: 0;
  transform: translate(-50%, -50%);
}

.right-arrow {
  right: 0;
  transform: translate(50%, -50%);
}

.gallery-iamge {
  width: 50vw;
  height: 50vh;
  display: block;
  object-fit: contain;
}

.close-gallery-button {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  text-align: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 999px;
  font-weight: 700;
}
h1 {
  padding: 50px;
}
</style>
