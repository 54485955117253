<template>
  <div class="container table-responsive">
    <div
      v-bind:class="editModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="editModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${editModal ? 'user-modal' : ''}`"
      >
        <div class="delete-button" @click="cancelEdit()">
          <span>x</span>
        </div>
        <div class="modal-common-content" v-if="currentAdvertisement">
          <h4>Edycja ogłoszenia</h4>
          <div class="modal-content">
            <div class="notification-input-row">
              <label>Nazwa</label>
              <input
                v-model="currentAdvertisement.title"
                maxlength="255"
                type="text"
              />

              <div style="display: flex; justify-content: center">
                <div style="width: 30%">
                  <label>Opis</label>
                </div>

                <div style="width: 70%; display: flex; justify-content: center">
                  <html-editor
                    @contentchanged="
                      handleHtmlEditorContentForDescription($event)
                    "
                    :startContent="currentAdvertisement.description"
                  />
                </div>
              </div>

              <!-- <textarea v-model="currentAdvertisement.description" rows="14" /> -->

              <label>Kategoria</label>
              <select v-model="categoryId">
                <option
                  :value="cat.id"
                  v-for="cat in jobsCategories"
                  :key="cat.id"
                  :selected="cat.id === currentAdvertisement.categoryId"
                >
                  {{ cat.title }}
                </option>
              </select>

              <label>Wynagrodzenie</label>
              <input
                v-model.number="currentAdvertisement.remuneration"
                min="0"
                type="number"
                precision="0.01"
                @blur="
                  currentAdvertisement.remuneration < 0
                    ? (currentAdvertisement.remuneration = 0)
                    : null
                "
              />

              <label>Telefon</label>
              <input
                v-model="currentAdvertisement.phone"
                maxlength="255"
                type="text"
              />

              <label>Email</label>
              <input
                v-model="currentAdvertisement.email"
                maxlength="255"
                type="text"
              />

              <br />
              <div style="display: flex; justify-content: center">
                <div style="width: 30%">
                  <label>Adres:</label>
                </div>
                <div style="width: 70%">
                  <div v-if="!changeAddress">
                    {{
                      `${currentAdvertisement.city} ${
                        currentAdvertisement.street || ""
                      } ${currentAdvertisement.address || ""} ${
                        currentAdvertisement.zipCode || ""
                      } `
                    }}
                    <span
                      @click="changeAddress = true"
                      style="
                        cursor: pointer;
                        text-decoration: underline;
                        font-weight: 800;
                      "
                      >Zmień adres</span
                    >
                  </div>
                  <GSearch v-else @newPlace="handleNewPlace"></GSearch>
                </div>
              </div>

              <div style="display: flex; justify-content: center">
                <div style="width: 30%">
                  <label>Data wygaśnięcia:</label>
                </div>

                <div style="width: 70%; display: flex; justify-content: center">
                  <Datepicker
                    v-model="currentAdvertisement.expirationDate"
                    format="dd/MM/yyyy"
                    :input-props="{ placeholder: 'Wybierz datę' }"
                    :language="pl"
                  ></Datepicker>
                </div>
              </div>

              <br />
              <b-button
                style="font-weight: 700; width: 100%; margin-bottom: 10px"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                id="filter-button"
                @click="
                  saveChangesInAdvertisementForApproval(currentAdvertisement.id)
                "
                :disabled="isAdvertisementForApprovalLoading"
              >
                Zapisz zmiany
              </b-button>

              <b-button
                style="font-weight: 700; width: 100%; margin-bottom: 10px"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                id="filter-button"
                @click="
                  saveChangesInAdvertisementForApprovalAndAdd(
                    currentAdvertisement.id,
                    currentAdvertisement.title
                  )
                "
                :disabled="isAdvertisementForApprovalLoading"
              >
                Zapisz zmiany i dodaj ogłoszenie
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="addModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="addModal"
        class="modal-wrapper add-modal"
        style="position: relative"
      >
        <div class="delete-button" @click="cancelAddModal()">
          <span>x</span>
        </div>
        <div class="modal-common-content">
          <h4>Dodawanie zleceń</h4>
          <div class="modal-content">
            <label for="oferent-token">Oferent TOKEN</label>
            <input
              :disabled="loading"
              v-model="oferentToken"
              type="text"
              name="oferent-token"
              id="oferent-token"
            />

            <div class="oferents-urls">
              <p>Linki do pobrania</p>
              <div
                class="oferent-url"
                v-for="(url, index) in oferentUrlsToAdd"
                :key="`url_${index}`"
              >
                <input type="text" v-model="oferentUrlsToAdd[index]" />
                <div @click="removeOferentUrl(index)">
                  <i
                    class="fa fa-times"
                    color="red"
                    style="
                      margin-left: 0.5rem;
                      cursor: pointer;
                      margin-top: 10px;
                    "
                  ></i>
                </div>
              </div>
            </div>
            <b-button
              style="font-weight: 700"
              class="btn btn-sm ml-1"
              variant="outline-primary"
              id="filter-button"
              @click="addNextOferentUrl()"
            >
              <i
                class="fa fa-plus"
                style="margin-right: 0.5rem"
                aria-hidden="true"
              ></i
              >Dodaj kolejny link
            </b-button>

            <b-button
              style="font-weight: 700; width: 100%; margin-bottom: 10px"
              class="btn btn-sm ml-1 mt-4"
              variant="outline-primary"
              @click="saveOferentLinks"
              :disabled="loading"
            >
              Pobierz zlecenia
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="addForTodayModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="addForTodayModal"
        class="modal-wrapper add-modal"
        style="position: relative"
      >
        <div class="delete-button" @click="cancelAddForTodayModal()">
          <span>x</span>
        </div>
        <div class="modal-common-content">
          <h4>Pobieranie zleceń z dzisiaj</h4>
          <div class="modal-content">
            <label for="oferent-token">Oferent TOKEN</label>
            <input
              :disabled="loading"
              v-model="oferentToken"
              type="text"
              name="oferent-token"
              id="oferent-token"
            />
            <b-button
              style="font-weight: 700; width: 100%; margin-bottom: 10px"
              class="btn btn-sm ml-1 mt-4"
              variant="outline-primary"
              @click="downloadAllOferentAdvFromToday"
              :disabled="loading"
            >
              Pobierz zlecenia z dzisiaj
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <p class="h1 fw-light mb-2 mb-sm-4">Lista zleceń do zaakceptowania</p>

    <b-button
      @click="openAddModal()"
      class="btn btn-sm w-100 mb-4"
      variant="outline-primary"
      >Dodaj zlecenia</b-button
    >

    <b-button
      @click="openAddForTodayModal()"
      class="btn btn-sm w-100 mb-4"
      variant="outline-primary"
      >Pobierz wszystkie zlecenia z dzisiaj</b-button
    >

    <!-- <div class="filter-wrapper">
      <div class="filter-actions-wrapper">
        <h2>Filtruj po nazwie</h2>
        <input type="text" v-model="search" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div> -->
    <table
      class="table align-middle mt-4"
      v-if="
        advertisementsForApprovalFiltered &&
        advertisementsForApprovalFiltered.length > 0
      "
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="advertisementForApproval in advertisementsForApprovalFiltered"
          :key="advertisementForApproval.id"
        >
          <td>
            {{
              advertisementForApproval.category &&
              advertisementForApproval.category.title
                ? advertisementForApproval.category.title
                : "Brak"
            }}
          </td>
          <td>
            {{
              advertisementForApproval.title
                ? advertisementForApproval.title
                : "Brak"
            }}
          </td>
          <td v-if="formattedDateAsNumbers">
            {{ formattedDateAsNumbers(advertisementForApproval.createdAt) }}
          </td>
          <td v-if="formattedDateAsNumbers">
            {{
              advertisementForApproval.expirationDate
                ? formattedDateAsNumbers(
                    advertisementForApproval.expirationDate
                  )
                : "Brak"
            }}
          </td>
          <td>
            {{
              formatAddress(
                advertisementForApproval.zipCode,
                advertisementForApproval.street,
                advertisementForApproval.address,
                advertisementForApproval.city
              )
            }}
          </td>
          <td
            v-html="
              advertisementForApproval.description.length > 100
                ? `${advertisementForApproval.description.slice(0, 100)}...`
                : advertisementForApproval.description
            "
          ></td>
          <td>{{ advertisementForApproval.remuneration }}</td>
          <td>{{ advertisementForApproval.phone }}</td>
          <td v-html="advertisementForApproval.email"></td>
          <td>
            <div class="d-flex gap-2 mb-2">
              <b-button
                @click="
                  handleAcceptAdvertisementForApproval(advertisementForApproval)
                "
                class="btn btn-sm"
                variant="primary"
                :disabled="
                  loadingCurrentAdvertisements[advertisementForApproval.id]
                "
                >Akceptuj</b-button
              >
              <b-button
                @click="
                  handleDeleteAdvertisementForApproval(
                    advertisementForApproval.id,
                    advertisementForApproval.title
                  )
                "
                class="btn btn-sm"
                variant="outline-primary"
                :disabled="
                  loadingCurrentAdvertisements[advertisementForApproval.id]
                "
                >Odrzuć</b-button
              >
            </div>
            <b-button
              @click="startEdit(advertisementForApproval)"
              class="btn btn-sm w-100"
              variant="outline-primary"
              :disabled="
                loadingCurrentAdvertisements[advertisementForApproval.id]
              "
              >Edytuj zlecenie</b-button
            >
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert
      v-else
      :content="'Brak zleceń do zaakceptowania'"
    ></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchAdvertisementsForApproval`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="search"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import GSearch from "@/components/GSearch";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { pl } from "vuejs-datepicker/dist/locale";
import HtmlEditor from "@/components/HtmlEditor.vue";
export default {
  components: { EmptyListAlert, Pagination, GSearch, Datepicker, HtmlEditor },
  name: "AdevertisementsForApproval",
  data: () => {
    return {
      columns: [
        "Kategoria",
        "Tytuł",
        "Data utworzenia",
        "Data wygaśnięcia",
        "Adres",
        "Opis",
        "Wynagrodzenie",
        "Telefon",
        "Email",
        "",
      ],
      search: "",
      currentAdvertisementForApproval: "",
      newAdvertisementForApprovalModal: false,
      title: "",
      buttonText: "",
      description: "",
      notificationUsers: [],
      advertisementForApprovalSearchText: "",
      foundUsers: [],
      timeoutId: "",
      formattedDateAsNumbers: null,
      currentAdvertisement: null,
      editModal: false,
      changeAddress: false,
      categoryId: null,
      place: null,
      pl,
      loading: false,
      addModal: false,
      oferentUrlsToAdd: [""],
      oferentToken: "",
      refreshAdvertisementsInterval: null,
      addForTodayModal: false,
      loadingCurrentAdvertisements: {},
      acceptedAdvertisementsForApprovalIds: [],
    };
  },

  watch: {
    oferentToken: function (newOferentToken) {
      if (newOferentToken.length > 0) {
        localStorage.setItem("oferentToken", newOferentToken);
      }
    },
  },
  destroyed() {
    clearInterval(this.refreshAdvertisementsInterval);
  },
  methods: {
    ...mapActions([
      "fetchAdvertisementsForApproval",
      "removeAdvertisementForApproval",
      "fetchAdvertisementsCategories",
      "editAdvertisementForApproval",
      "acceptAdvertisementForApproval",
      "addAdvertisementsForApproval",
    ]),

    cancelAddForTodayModal() {
      this.addForTodayModal = false;
    },
    openAddForTodayModal() {
      this.addForTodayModal = true;
    },

    handleHtmlEditorContentForDescription(html) {
      this.currentAdvertisement.description = html;
    },
    isValidEmail(email) {
      return shared.isValidEmail(email);
    },
    openAddModal() {
      this.addModal = true;
    },
    cancelAddModal() {
      this.addModal = false;
    },
    addNextOferentUrl() {
      this.oferentUrlsToAdd.push("");
    },
    removeOferentUrl(index) {
      if (this.oferentUrlsToAdd.length > 1) {
        this.oferentUrlsToAdd.splice(index, 1);
      } else {
        this.$toast.open({
          message: `Wymagane jest dodanie przynajmniej jednego linku`,
          type: "error",
        });
      }
    },

    async downloadAllOferentAdvFromToday() {
      const response = confirm(
        "Czy na pewno chcesz pobrać wszystkie zlecenia z dzisiaj?"
      );
      if (!response) {
        return;
      }
      try {
        this.loading = true;
        await this.addAdvertisementsForApproval({
          oferentToken: this.oferentToken,
          urls: [],
          fromToday: true,
        });
        this.$toast.open({
          message: `Rozpoczęto pobieranie wszystkich zleceń z dzisiaj. Po zakończeniu pobierania zostaniesz poinformowany o wyniku poprzez wiadomość email`,
          type: "success",
        });
        this.addForTodayModal = false;
      } catch (error) {
        this.$toast.open({
          message: `Niepoprawny token oferenta`,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async saveOferentLinks() {
      try {
        this.loading = true;
        if (!this.oferentToken) {
          this.$toast.open({
            message: `Wprowadź token oferenta`,
            type: "error",
          });
          return;
        }
        if (this.oferentUrlsToAdd.length === 0) {
          this.$toast.open({
            message: `Wprowadź przyjmniej jeden link`,
            type: "error",
          });
          return;
        }
        //check all links are correct url links
        const urlRegex = new RegExp(
          "^(http|https)://[^\\s/$.?#].[^\\s]*$",
          "i"
        );
        for (let i = 0; i < this.oferentUrlsToAdd.length; i++) {
          if (!urlRegex.test(this.oferentUrlsToAdd[i])) {
            this.$toast.open({
              message: `Link ${this.oferentUrlsToAdd[i]} nie jest poprawny`,
              type: "error",
            });
            return;
          }
        }
        await this.addAdvertisementsForApproval({
          oferentToken: this.oferentToken,
          urls: this.oferentUrlsToAdd,
        });
        this.oferentUrlsToAdd = [""];
        this.addModal = false;
        this.$toast.open({
          message: `Rozpoczęto pobieranie wybranych zleceń. Po zakończeniu pobierania zostaniesz poinformowany o wyniku poprzez wiadomość email`,
          type: "success",
        });
      } catch (error) {
        if (error?.response?.data?.message === "Oferent token is invalid") {
          this.$toast.open({
            message: `Niepoprawny token oferenta`,
            type: "error",
          });
        } else {
          this.$toast.open({
            message: `Wystąpił błąd podczas dodawania zleceń`,
            type: "error",
          });
        }
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleFetch() {
      try {
        await this.fetchAdvertisementsForApproval({
          ...this.meta,
          search: this.search,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono ogłoszeń`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },

    formatAddress(zipCode, street, buildingNumber, city) {
      let address = "";
      if (street) {
        address += street;
      }
      if (buildingNumber) {
        address += ` ${buildingNumber}`;
      }
      if (zipCode) {
        address += ` ${zipCode}`;
      }
      if (city) {
        address += ` ${city}`;
      }
      if (address.trim() !== "") {
        return address.trim();
      } else {
        return "Brak";
      }
    },

    async handleDeleteAdvertisementForApproval(id, name) {
      const response = confirm(
        "Czy na pewno chcesz usunąć ogłoszenie \n\n" + name + " ?"
      );
      if (response) {
        await this.removeAdvertisementForApproval(id);
        await this.fetchAdvertisementsForApproval({
          ...this.meta,
        });
      }
    },

    async handleAcceptAdvertisementForApproval(advertisementForApproval) {
      const { id, title } = advertisementForApproval;
      this.currentAdvertisement = JSON.parse(
        JSON.stringify(advertisementForApproval)
      );
      this.categoryId = this.currentAdvertisement.category
        ? this.currentAdvertisement.category.id
        : null;
      if (!this.checkFieldsInCurrentAdvertisementAreFilled()) {
        return;
      }
      const response = confirm(
        "Czy na pewno chcesz zaakceptować ogłoszenie \n\n" + title + " ?"
      );
      if (response) {
        await this.acceptAdvertisementForApproval(id);
        this.$toast.open({
          message: `Ogłoszenie zostało dodane`,
          type: "success",
        });
        await this.fetchAdvertisementsForApproval({
          ...this.meta,
        });
      }
    },

    async saveChangesInAdvertisementForApproval(id) {
      const advertisementToEdit = JSON.parse(
        JSON.stringify(this.currentAdvertisement)
      );
      try {
        this.loadingCurrentAdvertisements[id] = true;
        if (!this.checkFieldsInCurrentAdvertisementAreFilled()) {
          return;
        }
        this.cancelEdit();
        await this.editAdvertisementForApproval({
          id,
          data: {
            title: advertisementToEdit.title,
            description: advertisementToEdit.description,
            remuneration: advertisementToEdit.remuneration,
            phone: advertisementToEdit.phone,
            email: advertisementToEdit.email,
            city: advertisementToEdit.city,
            street: advertisementToEdit.street,
            address: advertisementToEdit.address,
            zipCode: advertisementToEdit.zipCode,
            categoryId: this.categoryId,
            lat: advertisementToEdit.lat,
            lng: advertisementToEdit.lng,
            expirationDate: moment(advertisementToEdit.expirationDate).format(
              "YYYY-MM-DD"
            ),
          },
        });

        await this.fetchAdvertisementsForApproval({
          ...this.meta,
        });

        return true;
      } catch (error) {
        console.log(error);
        return false;
      } finally {
        if (this.loadingCurrentAdvertisements[id] !== undefined) {
          this.loadingCurrentAdvertisements[id] = false;
        }
      }
    },

    checkFieldsInCurrentAdvertisementAreFilled() {
      if (this.currentAdvertisement.email) {
        if (!this.isValidEmail(this.currentAdvertisement.email)) {
          this.$toast.open({
            message: `Niepoprawny adres email`,
            type: "error",
          });
          return false;
        }
      }

      const allFieldsFilled =
        this.currentAdvertisement.title &&
        this.currentAdvertisement.description &&
        this.currentAdvertisement.remuneration !== undefined &&
        this.currentAdvertisement.remuneration !== null &&
        (this.currentAdvertisement.phone || this.currentAdvertisement.email) &&
        this.currentAdvertisement.city &&
        this.currentAdvertisement.zipCode &&
        this.categoryId &&
        this.currentAdvertisement.lat &&
        this.currentAdvertisement.lng &&
        this.currentAdvertisement.expirationDate;
      if (!allFieldsFilled) {
        this.$toast.open({
          message: `Wszystkie pola muszą być wypełnione`,
          type: "error",
        });
        return false;
      }
      return true;
    },

    async saveChangesInAdvertisementForApprovalAndAdd(id, title) {
      try {
        const responseOk = await this.saveChangesInAdvertisementForApproval(id);
        if (responseOk) {
          await this.acceptAdvertisementForApproval(id);
          await this.fetchAdvertisementsForApproval({
            ...this.meta,
          });
          this.$toast.open({
            message: `Ogłoszenie zostało dodane - ${title}`,
            type: "success",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (this.loadingCurrentAdvertisements[id] !== undefined) {
          this.loadingCurrentAdvertisements[id] = false;
        }
      }
    },
    startEdit(advertisement) {
      this.currentAdvertisement = JSON.parse(JSON.stringify(advertisement));
      this.categoryId = advertisement.category
        ? advertisement.category.id
        : null;
      this.editModal = true;
    },

    cancelEdit() {
      this.editModal = false;
    },

    handleNewPlace(place) {
      this.place = place;
      this.currentAdvertisement.city = place.city;
      this.currentAdvertisement.street = place.street;
      this.currentAdvertisement.address = place.address;
      this.currentAdvertisement.zipCode = place.zipCode;
      this.changeAddress = false;
      this.currentAdvertisement.lat = Number(place.latitude);
      this.currentAdvertisement.lng = Number(place.longitude);
    },
  },

  async created() {
    try {
      const oferentTokenFromLocalStorage = localStorage.getItem("oferentToken");
      if (oferentTokenFromLocalStorage) {
        this.oferentToken = oferentTokenFromLocalStorage;
      }
      await this.fetchAdvertisementsCategories();
      await this.fetchAdvertisementsForApproval({
        ...this.meta,
      });
      this.refreshAdvertisementsInterval = setInterval(() => {
        this.fetchAdvertisementsForApproval({
          ...this.meta,
        });
      }, 30000);
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono kodów`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },
  mounted() {
    this.formattedDateAsNumbers = shared.formattedDatePlFormatWithoutTime;
  },

  computed: {
    ...mapGetters({
      advertisementsForApproval: "getAllAdvertisementsForApproval",
      meta: "getAdvertisementsForApprovalMeta",
      jobsCategories: "getJobsCategories",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
    isAdvertisementForApprovalLoading() {
      if (this.currentAdvertisement && this.currentAdvertisement.id) {
        return this.loadingCurrentAdvertisements[this.currentAdvertisement.id]
          ? this.loadingCurrentAdvertisements[this.currentAdvertisement.id]
          : false;
      } else {
        return false;
      }
    },

    advertisementsForApprovalFiltered() {
      return this.advertisementsForApproval
        ? this.advertisementsForApproval.filter((advertisement) => {
            return !this.acceptedAdvertisementsForApprovalIds.includes(
              advertisement.id
            );
          })
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/common-modal.scss";
@import "/src/styles/select.scss";
@import "/src/styles/variables.scss";
.container {
  max-width: 100%;
  padding: 0 2rem;
}
strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}

.notification-input-row {
  width: 100%;
  margin-top: 2rem;
}
.notification-input-row table {
  margin: 0 auto;
}
.notification-input-row label {
  margin-bottom: 2rem;
  width: 30%;
  font-weight: bold;
}
.notification-input-row input,
.notification-input-row textarea {
  width: 50%;
}
.found-users-table {
  margin-top: 2rem !important;
}
.found-users-table tbody tr {
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
}

.found-users-table tbody tr:hover {
  background: $primary;
  color: #fff;
}
.notifications-users {
  display: flex;
}
.notifications-users div:first-child,
.notifications-users div:nth-child(2) {
  width: 50%;
  margin: 0 auto;
}
.added-users {
  font-weight: bold;
  margin-top: 2rem;
}
.added-users-list p {
  margin: 0 !important;
  font-size: 15px;
}
label {
  width: 30%;
  text-align: left;
}
.add-modal {
  width: 70%;
}
.oferents-urls {
  margin-top: 3rem;
  text-align: left;
  height: 60%;
  overflow: auto;
  margin-bottom: 0.5rem;
  padding-right: 3rem;
}
.oferents-urls input {
  width: 100%;
  margin-top: 10px;
}
.oferents-urls button {
  margin-top: 50px;
  width: 100%;
}
.oferent-url {
  display: flex;
  align-items: baseline;
}
@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
}
</style>
