const state = {
  payments: [],
  meta: {},
  transactions: [],
};

const mutations = {
  SET_PAYMENTS(state, payments) {
    state.payments = payments;
  },

  SET_PAYMENTS_META(state, meta) {
    state.meta = meta;
  },

  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
};

const getters = {
  getAllPayments: (state) => state.payments,
  getPaymentsMeta: (state) => state.meta,
  getAllUserTransactions: (state) => state.transactions,
};

export default {
  state,
  mutations,
  getters,
};
