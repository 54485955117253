<template>
  <div class="chat-content-wrapper">
    <ul
      class="rounded bg-light h-100 p-2 chat-content"
      :style="{
        maxHeight: '57vh',
        overflow: 'auto',
        minHeight: '57vh',
        listStyle: 'none',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '10',
      }"
      ref="chatContainer"
    >
      <div class="close-modal">
        <div
          @click="$emit('closeModal')"
          style="
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background: red;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          x
        </div>
      </div>
      <div v-if="messages.length > 0">
        <chat-message
          v-for="(message, index) in messages"
          :key="message.id"
          :id="message.id"
          :message="message.text"
          :variant="
            variant(
              message.authorUser ? message.authorUser.id : message.authorUserId
            )
          "
          :user="
            message.authorUser
              ? message.authorUser.fullName
              : message.authorUserName || '-'
          "
          :continued="continued(index)"
          :sendDate="message.createdAt"
        />
      </div>
      <div v-else>
        <p>Brak wiadomości między użytkownikami</p>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ChatMessage from "../components/Chat/ChatMessage.vue";
import shared from "@/shared";

export default {
  components: { ChatMessage },
  name: "ChatDetails",

  props: {
    // chatId: {
    //   type: String,
    //   required: true,
    // },
    // chatDetails: {
    //   type: Object,
    //   required: true,
    // },

    userId: {
      type: String,
      required: true,
    },

    messages: {
      type: Array,
      required: true,
    },
    chatCreation: {
      type: String,
      required: true,
    },
  },

  // data: () => {
  //   return {
  //     userMessages: [...this.messages],
  //     chatCreationDate: this.chatCreation,
  //   };
  // },

  created() {
    this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
  },

  // computed: {
  //   ...mapGetters({
  //     messages: "getMessages",
  //   }),
  // },

  // destroyed() {
  //   this.$store.commit("SET_MESSAGES", {
  //     messages: [],
  //     pagination: {},
  //   });
  // },

  methods: {
    ...mapActions(["fetchChatMessages"]),

    async loadMore($state) {
      await this.fetchChatMessages({
        perPage: 20,
        pageNumber: +this.messages.pagination.pageNumber + 1 || 1,
        chatId: this.chatId,
      });
      if (this.messages.messages.length < this.messages.pagination.total) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },

    continued(index) {
      if (index >= 1) {
        if (this.messages[index].authorUserId) {
          return (
            this.messages[index].authorUserId ===
            this.messages[index - 1].authorUserId
          );
        } else {
          return (
            this.messages[index].authorUser.id ===
            this.messages[index - 1].authorUser.id
          );
        }
      } else return false;
    },

    variant(authorId) {
      return authorId === this.userId ? "sender" : "receiver";
    },

    user(authorId) {
      if (authorId === this.userId) {
        if (authorId === this.chatDetails.userId)
          return this.chatDetails.username || "Bieżący użytkownik";
        else if (authorId === this.chatDetails.announcementIssuerUserId)
          return (
            this.chatDetails.announcementIssuerName || "Bieżący użytkownik"
          );
      } else {
        if (authorId === this.chatDetails.userId)
          return this.chatDetails.username || "Inny użytkownik";
        else if (authorId === this.chatDetails.announcementIssuerUserId)
          return this.chatDetails.announcementIssuerName || "Inny Użytkownik";
      }
    },

    email(authorId) {
      return authorId === this.chatDetails.userId
        ? this.chatDetails.userEmail
        : this.chatDetails.announcementIssuerEmail;
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0.45rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, 0.4);
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.chat-content-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.chat-content {
  width: 50vh;
  @media screen and (max-width: 768px) {
    width: 80vw;
  }
}
</style>
