export const parseDate = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month =
    newDate.getMonth() + 1 > 10
      ? newDate.getMonth() + 1
      : `0${newDate.getMonth() + 1}`;
  const year = newDate.getFullYear();
  return `${day}/${month}/${year}`;
};
