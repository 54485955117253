const state = {
  attractions: [],
  categories: [],
  reports:[],
  meta: {},
  attraction:{},
  meta2:{}
};

const mutations = {
  SET_ATTRACTIONS(state, attractions) {
    state.attractions = attractions;
  },
  SET_ATTRACTION(state, attraction) {
    state.attraction = attraction;
  },
  SET_ATTRACTIONS_META(state, meta) {
    state.meta = meta;
  },
  SET_ATTRACTIONS_REPORTS_META(state, meta) {
    state.meta2 = meta;
  },
  SET_ATTRACTIONS_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_ATTRACTIONS_REPORTS(state, reports) {
    state.reports = reports;
  },
};

const getters = {
  getAllAttractions: (state) => state.attractions,
  getAttraction:(state)=>state.attraction,
  getAllAttractionsCategories: (state) => state.categories,
  getAttractionsMeta: (state) => state.meta,
  getAttractionsReportsMeta: (state) => state.meta2,
  getAllAttractionReports: (state) => state.reports,
};

export default {
  state,
  mutations,
  getters,
};
