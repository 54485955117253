<template>
  <div class="content">
    <div style="display: flex">
      <div>
        <div @click="previousPage()" v-if="this.pageNumber > 1">
          <i class="fas fa-arrow-circle-left"></i>
        </div>
        <div v-else>
          <i class="fas fa-arrow-circle-left disabled-icon"></i>
        </div>
      </div>
      <div>
        <div @click="nextPage()" v-if="calcPage()">
          <i class="fas fa-arrow-circle-right"></i>
        </div>
        <div v-else>
          <i class="fas fa-arrow-circle-right disabled-icon"></i>
        </div>
      </div>
    </div>
    <div class="pages" v-if="this.total">
      {{ Math.round(this.total / this.perPage) > 0 ? pageNumber + " /" : "" }}
      {{
        Math.round(this.total / this.perPage) > 0
          ? Math.ceil(this.total / this.perPage)
          : ""
      }}
      ({{ this.total }})
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "pageNumber",
    "perPage",
    "total",
    "dispatchActionName",
    "fetchParams",
    "status",
    "searchUser",
    "type",
  ],

  emits: ["pageChanged"],

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    calcPage() {
      const pageNumber = this.pageNumber;
      const total = this.total;
      const perPage = this.perPage;
      if (total / perPage > pageNumber) {
        return true;
      } else {
        return false;
      }
    },

    async nextPage() {
      this.loading = true;
      this.$router.push({
        path: this.$route.fullpath,
        query: { page: +this.pageNumber + 1 },
      });
      await this.$store.dispatch(this.dispatchActionName, {
        per_page: this.perPage,
        current_page: +this.pageNumber + 1,
        searchUser: this.searchUser,
        status: this.status,
        type: this.type,
        ...this.fetchParams,
      });
      this.$emit("pageChanged");
      this.loading = false;
    },
    async previousPage() {
      this.loading = true;
      this.$router.push({
        path: this.$route.fullpath,
        query: { page: +this.pageNumber - 1 },
      });

      await this.$store.dispatch(this.dispatchActionName, {
        per_page: this.perPage,
        current_page: +this.pageNumber - 1,
        status: this.status,
        searchUser: this.searchUser,
        type: this.type,
        ...this.fetchParams,
      });
      this.$emit("pageChanged");
      this.loading = false;
    },
  },

  computed: {
    page() {
      return this.$route.query.page;
    },
  },
  watch: {
    async page() {
      await this.$store.dispatch(this.dispatchActionName, {
        per_page: this.perPage,
        current_page: this.page,
        status: this.status,
        searchUser: this.searchUser,
        type: this.type,
        ...this.fetchParams,
      });
      this.$emit("pageChanged");
    },
  },
  async created() {
    await this.$store.dispatch(this.dispatchActionName, {
      per_page: this.perPage,
      current_page: this.$route.query.page,
      status: this.status,
      searchUser: this.searchUser,
      type: this.type,
      ...this.fetchParams,
    });
  },
};
</script>

<style scoped>
.menagment-panel .disabled-icon {
  color: rgba(182, 182, 182, 0.571);
}

.menagment-panel i {
  font-size: 30px;
  cursor: pointer;
  margin: 5px;
  transform: translateY(7px);
}
.content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 1000px;
}
.pages {
  font-size: 14px;
  font-weight: bold;
}
.content svg {
  font-size: 30px;
  margin: 0 5px 0 5px !important;
  cursor: pointer;
}
.disabled-icon {
  color: rgba(182, 182, 182, 0.571);
}
</style>
