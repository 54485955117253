import axios from "../../axios.js";

const fetchUserChats = async ({ commit, state }, data) => {
  const perPage = data.perPage ? `onPage=${data.perPage}&` : "";
  const pageNumber = data.pageNumber ? `pageNumber=${data.pageNumber}` : "";
  const response = await axios.get(
    `api/chats/users/${data.userId}?${perPage}${pageNumber}`
  );

  commit("SET_CHATS", {
    ...response.data.data,
    chats: [...state.chat.chats.chats, ...response.data.data.chats],
  });
};

const fetchChatMessages = async ({ commit, state }, data) => {
  const perPage = data.perPage ? `onPage=${data.perPage}&` : "";
  const pageNumber = data.pageNumber ? `pageNumber=${data.pageNumber}` : "";
  const response = await axios.get(
    `api/chats/${data.chatId}/messages?${perPage}${pageNumber}`
  );
  commit("SET_MESSAGES", {
    ...response.data.data,
    messages: [
      ...response.data.data.messages.reverse(),
      ...state.chat.messages.messages,
    ],
  });
};

const fetchAllUserChats = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}` : "";
  const url = `admins/advertisements/offers?${perPage}${pageNumber}${searchUser}`;
  try {
    const response = await axios.get(url);
    commit("SET_USERS_CHATS", response.data.data);
    commit("SET_USERS_CHATS_META", response.data.meta);
  } catch (err) {
    throw { status: err.response?.status };
  }
};

export default {
  fetchUserChats,
  fetchChatMessages,
  fetchAllUserChats,
};
