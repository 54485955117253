const state = {
  discounts: [],
  meta: {},
};

const mutations = {
  SET_DISCOUNTS(state, discounts) {
    state.discounts = discounts;
  },
  SET_DISCOUNTS_META(state, meta) {
    state.meta = meta;
  },
};

const getters = {
  getAllDiscounts: (state) => state.discounts,
  getDiscountsMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
};
