const state = {
    contractors: [],
    categories:[],
    meta: {},
  };
  
  const mutations = {
    SET_CONTRACTORS(state, contractors) {
      state.contractors = contractors;
    },
    SET_CONTRACTORS_CATEGORIES(state, categories) {
      state.categories = categories;
    },
  
    SET_CONTRACTORS_META(state, meta) {
      state.meta = meta;
    },
  };
  
  const getters = {
    getContractors: (state) => state.contractors,
    getContractorsCategories: (state) => state.categories,
    getContractorsMeta: (state) => state.meta,
  };
  
  export default {
    state,
    mutations,
    getters,
  };
  