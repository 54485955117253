import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import auth from "./modules/auth";
import users from "./modules/users";
import payments from "./modules/payments";
import notifications from "./modules/notifications";
import documents from "./modules/documents";
import chat from "./modules/chat";
import jobs from "./modules/jobs";
import reports from "./modules/reports";
import contractors from "./modules/contractors";
import notificationsForAcceptance from "./modules/notificationsForAcceptance";
import attractions from "./modules/attractions";
import discount from "./modules/discount";
import advertisementsForApproval from "./modules/advertisementsForApproval";
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "FUCHA_STATE",
      paths: [
        "auth",
        "users",
        "payments",
        "notifications",
        "documents",
        "chat",
        "jobs",
        "reports",
        "attractions",
        "notificationsForAcceptance",
        "contractors",
        "discount",
        "advertisementsForApproval",
      ],
    }),
  ],
  actions,
  modules: {
    auth,
    users,
    payments,
    notifications,
    documents,
    chat,
    jobs,
    reports,
    attractions,
    notificationsForAcceptance,
    contractors,
    discount,
    advertisementsForApproval,
  },
});
