<template>
  <div class="container table-responsive">
    <div
      v-bind:class="showModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="showModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${showModal ? 'user-modal' : ''}`"
      >
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
          "
          @click="handleCancel($event)"
        >
          <span>x</span>
        </div>
        <div
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4>Lista użytkowników, którzy skorzystali z kodu</h4>
          <div class="modal-content">
            <table
              class="table align-middle"
              v-if="currentDiscount && currentDiscount.length > 0"
              style="margin-bottom: 30px"
            >
              <thead>
                <tr>
                  <th>Numer telefonu</th>
                  <th>Imię i nazwisko</th>
                  <th>Data użycia</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="discountUse in currentDiscount"
                  :key="discountUse.id"
                >
                  <td>
                    {{ discountUse.subscription.user.phone || "" }}
                  </td>
                  <td>
                    {{ discountUse.subscription.user.fullName || "Brak" }}
                  </td>
                  <td>
                    {{ formattedDateAsNumbers(discountUse.createdAt) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class=""
              v-if="currentDiscount && currentDiscount.length === 0"
            >
              Brak użytkowników
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="newDiscountModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="newDiscountModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${newDiscountModal ? 'user-modal' : ''}`"
      >
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
          "
          @click="cancelNewDiscountModal()"
        >
          <span>x</span>
        </div>
        <div
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4>Dodawanie nowego kodu rabatowego</h4>
          <div class="modal-content">
            <div class="notification-input-row">
              <label>Kod</label>
              <input v-model="title" maxlength="255" type="text" />

              <br />
              <b-button
                style="font-weight: 700; width: 100%"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                id="filter-button"
                @click="saveNewCode"
              >
                <i
                  class="fa fa-plus"
                  style="margin-right: 0.5rem"
                  aria-hidden="true"
                ></i
                >Dodaj
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="h1 fw-light mb-2 mb-sm-4">Lista kodów rabatowych</p>
    <b-button
      style="font-weight: 700"
      class="btn btn-sm ml-1"
      variant="outline-primary"
      id="filter-button"
      @click="showNewDiscountModal"
    >
      <i class="fa fa-plus" style="margin-right: 0.5rem" aria-hidden="true"></i
      >Dodaj nowy kod rabatowy
    </b-button>
    <div class="filter-wrapper">
      <div class="filter-actions-wrapper">
        <h2>Filtruj po nazwie</h2>
        <input type="text" v-model="search" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="discounts && discounts.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="discount in discounts" :key="discount.id">
          <td style="position: relative">
            <div class="action-column">
              <div
                @click="handleDeleteDiscount(discount.id, discount.code)"
                class="danger-button"
              >
                <span>x</span>
                <span class="hint">Usuń kod</span>
              </div>
            </div>
          </td>
          <td>{{ discount.code || "" }}</td>
          <td>{{ discount.type === "SUBSCRIPTION" ? "Subskrypcja" : "-" }}</td>
          <td>{{ discount.value || "" }}</td>
          <td v-if="formattedDateAsNumbers">
            {{ formattedDateAsNumbers(discount.createdAt) }}
          </td>
          <td class="link" @click="handleShowModal(discount)">
            Lista użytkowników
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert
      v-else
      :content="'Brak kodów rabatowych'"
    ></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchDiscounts`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="search"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { parseDate } from "../utils/helpers";

export default {
  components: { EmptyListAlert, Pagination },
  name: "notificationsForAcceptance",
  data: () => {
    return {
      columns: ["", "Kod", "Typ", "Zniżka", "Data utworzenia", "Użyte przez"],
      search: "",
      currentDiscount: "",
      showModal: false,
      modifyDate: parseDate,
      newDiscountModal: false,
      title: "",
      buttonText: "",
      description: "",
      notificationUsers: [],
      discountSearchText: "",
      foundUsers: [],
      timeoutId: "",
    };
  },

  methods: {
    ...mapActions(["fetchDiscounts", "addDiscountCode", "deleteDiscountCode"]),
    async handleFetch() {
      try {
        await this.fetchDiscounts({
          ...this.meta,
          search: this.search,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono kodów`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "DiscountCodes",
        query: {
          search: this.search,
        },
      });
      await this.handleFetch();
    },
    handleReset() {
      this.search = "";
      this.$router.push({
        name: "DiscountCodes",
      });
      this.handleFetch();
    },
    handleCancel() {
      this.currentDiscount = [];
      this.showModal = false;
    },
    handleShowModal(discount) {
      this.currentDiscount = discount.usedDiscountCodes;
      this.showModal = true;
    },
    translateNotificationForAcceptanceConfirmationStatus(status) {
      if (status === "CONFIRMED") {
        return "Potwierdził";
      } else {
        return "Nie potwierdził";
      }
    },

    async showNewDiscountModal() {
      this.newDiscountModal = true;
    },
    cancelNewDiscountModal() {
      this.newDiscountModal = false;
    },
    deleteNotificationUser(user) {
      this.notificationUsers = this.notificationUsers.filter(
        (n) => n.id !== user.id
      );
    },
    addUserToNotificationUsers(user) {
      const alreadyAddded = this.notificationUsers.find(
        (u) => u.id === user.id
      );
      if (!alreadyAddded) {
        this.notificationUsers.push({
          id: user.id,
          phone: user.phone,
        });
      }
    },

    async handleDeleteDiscount(id, name) {
      const response = confirm(
        "Czy na pewno chcesz usunąć kod znizkowy " + name + " ?"
      );
      if (response) {
        await this.deleteDiscountCode(id);
        await this.fetchDiscounts({
          ...this.meta,
          search: this.search,
        });
      }
    },

    async saveNewCode() {
      if (!this.title) {
        this.$toast.open({
          message: `Uzupełnij nazwę kodu rabatowego, aby go dodać.`,
          type: "error",
        });
      } else {
        try {
          await this.addDiscountCode({
            code: this.title,
          });
          this.$toast.open({
            message: `Kod został dodany`,
            type: "success",
          });

          this.cancelNewDiscountModal();
          this.title = "";
          await this.fetchDiscounts({
            ...this.meta,
            search: this.search,
          });
        } catch (err) {
          if (err?.response.status === 400) {
            this.$toast.open({
              message: `Taki kod już istnieje. Dodaj kod rabatowy o unikalnej nazwie.`,
              type: "error",
            });
            return;
          }
          if (err.status === 403) {
            this.$toast.open({
              message: `Operacja zabroniona`,
              type: "error",
            });
          } else if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono kodów`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },
  async created() {
    const { search: querySearch } = this.$router.history.current.query;
    this.search = querySearch || "";
    try {
      await this.fetchDiscounts({
        ...this.meta,
        search: this.search,
      });
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono kodów`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },
  mounted() {
    this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    window.addEventListener("keypress", this.handleKeyPress);
    const searchFromQuery = this.$route.query.s;
    const newNotification = this.$route.params.newNotification;
    if (searchFromQuery) {
      this.discountSearchText = searchFromQuery;
      if (newNotification) {
        this.showNewDiscountModal(searchFromQuery);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  computed: {
    ...mapGetters({
      discounts: "getAllDiscounts",
      meta: "getDiscountsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/variables.scss";

strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}

.notification-input-row {
  width: 100%;
  margin-top: 2rem;
}
.notification-input-row table {
  margin: 0 auto;
}
.notification-input-row label {
  margin-bottom: 2rem;
  width: 30%;
  font-weight: bold;
}
.notification-input-row input,
.notification-input-row textarea {
  width: 50%;
}
.found-users-table {
  margin-top: 2rem !important;
}
.found-users-table tbody tr {
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
}

.found-users-table tbody tr:hover {
  background: $primary;
  color: #fff;
}
.notifications-users {
  display: flex;
}
.notifications-users div:first-child,
.notifications-users div:nth-child(2) {
  width: 50%;
  margin: 0 auto;
}
.added-users {
  font-weight: bold;
  margin-top: 2rem;
}
.added-users-list p {
  margin: 0 !important;
  font-size: 15px;
}
@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
}
</style>
