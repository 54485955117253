import axios from "../../axios.js";

const fetchUsers = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}&` : "";
  const status = data.status ? `status=${data.status}` : "";
  const url = `admins/users?${perPage}${pageNumber}${searchUser}${status}`;
  try {
    const response = await axios.get(url);
    commit("SET_USERS", response.data.data);
    commit("SET_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const deleteUser = async (_, data) => {
  try {
    const response = await axios.put(`admins/users/${data.id}`);
    return response.data.status;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const blockOrUnblockUser = async (_, data) => {
  try {
    const response = await axios.put(
      `admins/users/${data.userId}/${data.actionType}`
    );
    return response.data.status;
  } catch (err) {
    throw { status: err.response.status };
  }
};

const fetchUserAvatar = async (_, avatarId) => {
  const url = `cdn/files/${avatarId}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });
    return URL.createObjectURL(response.data);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const editUserNote = async (_, data) => {
  const url = `admins/users/${data.id}/data`;
  try {
    const response = await axios.put(url, {
      note: data.note,
    });
    return response.data;
  } catch (e) {
    throw { status: e.response.status };
  }
};

export default {
  fetchUsers,
  deleteUser,
  blockOrUnblockUser,
  fetchUserAvatar,
  editUserNote,
};
