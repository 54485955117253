import axios from "../../axios.js";

const fetchPayments = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "limit=20&";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}&` : "";
  const status = data.status ? `status=${data.status}` : "";
  try {
    const response = await axios.get(
      `payouts?${perPage}${pageNumber}${searchUser}${status}`
    );
    commit("SET_PAYMENTS", response.data.data);
    commit("SET_PAYMENTS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const processPayment = async (_, data) => {
  try {
    const response = await axios.post("payouts/status", data);
    return response.data.status;
  } catch (err) {
    throw { status: err.response.status };
  }
};

const fetchUserTransactions = async ({ commit }, data) => {
  try {
    const perPage = data.per_page ? `limit=${data.per_page}&` : "";
    const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
    const searchUser = data.searchUser ? `searchUser=${data.searchUser}&` : "";
    const type = data.type ? `type=${data.type}` : "";
    const response = await axios.get(
      `admin/transactions?${perPage}${pageNumber}${searchUser}${type}`
    );
    commit("SET_TRANSACTIONS", response.data.data);
    commit("SET_PAYMENTS_META", response.data.meta);
  } catch (err) {
    throw { status: err.response.status };
  }
};

const exportPayouts = async (_, data) => {
  try {
    const response = await axios.post(`payouts/csv`, { ids: data.ids });
    return response;
  } catch (err) {
    throw { status: err.response.status };
  }
};

export default {
  fetchPayments,
  processPayment,
  fetchUserTransactions,
  exportPayouts,
};
