import axios from "../../axios.js";

const fetchAttractions = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchAttraction =
    data.searchAttraction || data.searchUser
      ? `searchAttraction=${data.searchAttraction || data.searchUser}`
      : "";

  const response = await axios.get(
    `attractions?${perPage}${pageNumber}${searchAttraction}`
  );
  commit("SET_ATTRACTIONS", response.data.data);
  commit("SET_ATTRACTIONS_META", response.data.meta);
};
const fetchAttraction = async ({ commit }, id) => {
  const response = await axios.get(`attractions/${id}/details`);
  commit("SET_ATTRACTION", response.data);
  return response.data;
};
const fetchAttractionCategories = async ({ commit }) => {
  const response = await axios.get(`attractions/categories`);
  commit("SET_ATTRACTIONS_CATEGORIES", response.data);
};
const fetchAttractionReports = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}` : "";
  const response = await axios.get(
    `admins/attractionsReports?${perPage}${pageNumber}`
  );
  commit("SET_ATTRACTIONS_REPORTS", response.data.data);
  commit("SET_ATTRACTIONS_REPORTS_META", response.data.meta);
};

const addAttraction = async (_, body) => {
  const res = await axios.post(`attractions`, body);
  //dispatch("fetchAttractions", { per_page: 10, current_page: 1 });
  return res;
};
const editAttraction = async (_, body) => {
  const { id, ...obj } = body;
  await axios.put(`attractions/${id}`, obj);
  //dispatch("fetchAttractions", { per_page: 10, current_page: 1 });
};
const toggleAttractionVisibility = async (_, body) => {
  await axios.put(
    `attractions/${body.id}/visibility-permanent?status=${body.visible}`
  );
  //dispatch("fetchAttractions", { per_page: 10, current_page: 1 });
};
const deleteAttraction = async (_, id) => {
  await axios.delete(`attractions/${id}`);
  //dispatch("fetchAttractions", { per_page: 10, current_page: 1 });
};

export default {
  fetchAttractions,
  fetchAttractionCategories,
  addAttraction,
  deleteAttraction,
  fetchAttraction,
  fetchAttractionReports,
  toggleAttractionVisibility,
  editAttraction,
};
