const state = {
  notificationsForAcceptance: [],
  meta: {},
  confirmations: [],
  confirmationsMeta: {},
};

const mutations = {
  SET_NOTIFICATIONS_FOR_ACCEPTANCE(state, notifications) {
    state.notificationsForAcceptance = notifications;
  },

  SET_NOTIFICATIONS_FOR_ACCEPTANCE_META(state, meta) {
    state.meta = meta;
  },
  SET_NOTIFICATIONS_CONFIRMATIONS(state, confirmations) {
    state.confirmations = confirmations;
  },
  SET_NOTIFICATIONS_CONFIRMATIONS_META(state, meta) {
    state.confirmationsMeta = meta;
  },
};

const getters = {
  getNotificationsForAcceptance: (state) => state.notificationsForAcceptance,
  getNotificationsForAcceptanceMeta: (state) => state.meta,
  getAllNotificationsConfirmations: (state) => state.confirmations,
  getAllNotificationsConfirmationsMeta: (state) => state.confirmationsMeta,
};

export default {
  state,
  mutations,
  getters,
};
