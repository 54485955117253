<template>
  <li
    class="my-1 w-full d-flex justify-content-end align-items-center"
    :class="containerClasses"
  >
    <div class="card rounded px-2 py-2 d-flex flex-column text-start">
      <small style="font-size: 9px" class="text-muted">{{
        formattedDateAsNumbers(sendDate)
      }}</small>
      <small v-if="user !== '-'" style="font-size: 9px" class="text-muted">{{
        user
      }}</small>
      <span class="mt-1">{{ message }}</span>
    </div>
  </li>
</template>

<script>
import shared from "@/shared";

export default {
  name: "ChatMessage",

  props: {
    id: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
      validate: (val) => {
        return val in ["sender", "receiver"];
      },
    },
    user: {
      type: String,
      required: true,
    },

    continued: {
      type: Boolean,
      required: false,
    },

    sendDate: {
      type: String,
      required: false,
    },
  },

  created() {
    this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
  },

  computed: {
    avatarCaption() {
      return this.email.charAt(0);
    },

    avatarClasses() {
      return [
        "text-white",
        "mx-2",
        this.variant === "sender" ? "bg-primary" : "bg-secondary",
      ];
    },

    containerClasses() {
      return {
        "flex-row-reverse": this.variant === "sender",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
