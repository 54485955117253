const state = {
  jobs: [],
  meta: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  jobsCategories: [],
};

const mutations = {
  SET_JOBS(state, jobs) {
    state.jobs = jobs;
  },
  SET_JOBS_META(state, meta) {
    state.meta = meta;
  },
  SET_JOBS_CATEGORIES(state, jobsCategories) {
    state.jobsCategories = jobsCategories;
  },
};

const getters = {
  getJobs: (state) => state.jobs,
  getJobsMeta: (state) => state.meta,
  getJobsCategories: (state) => state.jobsCategories,
};

export default {
  state,
  mutations,
  getters,
};
