<template>
  <div class="container">
    <p>Dodaj plik CSV aby utworzyć zlecenie</p>
    <input ref="fileInput" type="file" @change="onFileChange" accept=".csv" />
    <br />
    <b-button
      style="font-weight: 700"
      class="btn btn-sm ml-1 mt-4"
      variant="outline-primary"
      @click="handleAddAdvertisements"
      >Dodaj zlecenia
    </b-button>

    <div
      class="created-advertisements"
      v-if="createdAdvertisements && createdAdvertisements.length > 0"
    >
      <b>Utworzono zlecenia:</b>
      <p v-for="(a, index) in createdAdvertisements" :key="a.id">
        {{ index + 1 }}. {{ a.title }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "../axios";
export default {
  components: {},
  name: "UploadAdvertisements",
  data: () => {
    return {
      file: null,
      createdAdvertisements: [],
    };
  },

  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
    },
    async handleAddAdvertisements() {
      try {
        if (!this.file) {
          this.$toast.open({
            message: `Wybierz plik csv do dodania`,
            type: "error",
          });
          return;
        }
        const url = `admins/advertisements/csv`;
        const formData = new FormData();
        formData.append("file", this.file);
        const response = await axios.post(url, formData);
        const advertisements = response.data;
        this.createdAdvertisements = advertisements;
        setTimeout(() => {
          this.createdAdvertisements = [];
        }, 5000);
        this.file = null;
        this.$refs.fileInput.value = "";
        this.$toast.open({
          message: `Pomyślnie dodano ${this.createdAdvertisements.length} zlecenia`,
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: `Nie udało się dodać zleceń. Sprawdź czy plik csv jest poprawny`,
          type: "error",
        });
      }
    },
  },

  async created() {
    console.log(axios);
  },

  computed: {},
};
</script>

<style scoped>
.created-advertisements {
  margin-top: 2rem;
}
.container {
  margin: 0 auto;
  width: 300px;
  text-align: left;
}
</style>
