<template>
  <div class="container table-responsive">
    <div
      v-bind:class="showModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="showModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${showModal ? 'user-modal' : ''}`"
      >
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
          "
          @click="handleCancel($event)"
        >
          <span>x</span>
        </div>
        <div
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4>Lista użytkowników</h4>
          <div class="modal-content">
            <table
              class="table align-middle"
              v-if="
                currentNotificationConfirmations &&
                currentNotificationConfirmations.length > 0
              "
              style="margin-bottom: 30px"
            >
              <thead>
                <tr>
                  <th>Numer</th>
                  <th>Imię i nazwisko</th>
                  <th>Czy potwierdził?</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="currentNotificationConfirmation in currentNotificationConfirmations"
                  :key="currentNotificationConfirmation.id"
                >
                  <td>
                    {{ currentNotificationConfirmation.user.phone || "" }}
                  </td>
                  <td>
                    {{
                      currentNotificationConfirmation.user.fullName || "Brak"
                    }}
                  </td>
                  <td>
                    {{
                      translateNotificationForAcceptanceConfirmationStatus(
                        currentNotificationConfirmation.status
                      ) || ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button
              v-if="hasMorePagesConfirmations()"
              style="
                font-weight: 700;
                width: 250px;
                margin: auto;
                margin-top: 5px;
              "
              class="btn btn-sm ml-1 mt-4"
              variant="outline-primary"
              id="filter-button"
              @click="showMoreConfirmations"
            >
              Pokaż więcej
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="newNotificationModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="newNotificationModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${newNotificationModal ? 'user-modal' : ''}`"
      >
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
          "
          @click="cancelNewNotificationModal()"
        >
          <span>x</span>
        </div>
        <div
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4>Dodawanie nowego powiadomienia</h4>
          <div class="modal-content">
            <div class="notification-input-row">
              <label>Tytuł</label>
              <input v-model="title" maxlength="255" type="text" />

              <label>Treść przycisku</label>
              <input v-model="buttonText" maxlength="255" type="text" />

              <label>Opis</label>
              <textarea v-model="description" maxlength="8000" />

              <br />
              <b-button
                style="font-weight: 700; width: 100%"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                id="filter-button"
                @click="saveNewNotification"
              >
                <i
                  class="fa fa-plus"
                  style="margin-right: 0.5rem"
                  aria-hidden="true"
                ></i
                >Wyślij
              </b-button>

              <div class="notifications-users">
                <div>
                  <p style="font-weight: bold; margin-top: 2rem">
                    Wyszukiwanie użytkownika:
                  </p>

                  <input
                    style="width: 250px"
                    v-model="notificationSearchText"
                  />
                  <b-button
                    v-if="foundUsers && foundUsers.length > 0"
                    style="font-weight: 700; width: 250px; margin-top: 5px"
                    class="btn btn-sm ml-1"
                    variant="outline-primary"
                    id="filter-button"
                    @click="addAllFoundUsersToNotificationUsers"
                  >
                    <i
                      class="fa fa-plus"
                      style="margin-right: 0.5rem"
                      aria-hidden="true"
                    ></i
                    >Dodaj wszystkich użytkowników
                    {{ foundUsers ? `(${foundUsers.length})` : "" }}
                  </b-button>
                  <table
                    class="found-users-table"
                    v-if="foundUsers && foundUsers.length > 0"
                  >
                    <thead>
                      <tr>
                        <th>Numer</th>
                        <th>Imię i nazwisko</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="user in foundUsers.slice(0, showedUsers)"
                        :key="user.id"
                        @click="addUserToNotificationUsers(user)"
                      >
                        <td>{{ user.phone }}</td>
                        <td>{{ user.fullName || "Brak" }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <b-button
                    v-if="foundUsers && foundUsers.length > 0"
                    style="font-weight: 700; width: 250px; margin-top: 5px"
                    class="btn btn-sm ml-1 mt-4"
                    variant="outline-primary"
                    id="filter-button"
                    @click="showMoreUsers"
                  >
                    Pokaż więcej
                  </b-button>
                </div>

                <div>
                  <p class="added-users">
                    Dodani użytkownicy ({{
                      notificationUsers && notificationUsers.length > 0
                        ? `${notificationUsers.length}`
                        : "0"
                    }})
                  </p>
                  <div
                    class="added-users-list"
                    v-if="notificationUsers && notificationUsers.length > 0"
                  >
                    <p
                      v-for="(
                        notificationUser, index
                      ) in notificationUsers.slice(0, showedSelectedUsers)"
                      :key="notificationUser.id"
                    >
                      <span>{{ notificationUser.phone }}</span>
                      <span
                        @click="
                          deleteNotificationUser(index, notificationUser.id)
                        "
                      >
                        <i
                          class="fa fa-minus"
                          style="margin-left: 0.5rem; cursor: pointer"
                        ></i>
                      </span>
                    </p>
                  </div>

                  <b-button
                    v-if="
                      notificationUsers &&
                      notificationUsers.length > 0 &&
                      notificationUsers.length > showedSelectedUsers
                    "
                    style="font-weight: 700; width: 250px; margin-top: 5px"
                    class="btn btn-sm ml-1 mt-4"
                    variant="outline-primary"
                    id="filter-button"
                    @click="showMoreUsersSelected"
                  >
                    Pokaż więcej
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="h1 fw-light mb-2 mb-sm-4">Lista powiadomień</p>
    <b-button
      style="font-weight: 700"
      class="btn btn-sm ml-1"
      variant="outline-primary"
      id="filter-button"
      @click="showNewNotificationModal"
    >
      <i class="fa fa-plus" style="margin-right: 0.5rem" aria-hidden="true"></i
      >Dodaj nowe powiadomienie
    </b-button>
    <div class="filter-wrapper">
      <div class="filter-actions-wrapper">
        <h2>Filtr użytkowników</h2>
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="notificationsForAcceptance && notificationsForAcceptance.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="notificationForAcceptance in notificationsForAcceptance"
          :key="notificationForAcceptance.id"
        >
          <td>{{ notificationForAcceptance.title || "" }}</td>
          <td>{{ notificationForAcceptance.description || "" }}</td>
          <td>{{ notificationForAcceptance.buttonText || "" }}</td>
          <td v-if="formattedDateAsNumbers">
            {{ formattedDateAsNumbers(notificationForAcceptance.createdAt) }}
          </td>
          <td class="link" @click="handleShowModal(notificationForAcceptance)">
            Lista użytkowników
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak powiadomień'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchNotificationsForAcceptance`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { parseDate } from "../utils/helpers";
import axios from "../axios";

export default {
  components: { EmptyListAlert, Pagination },
  name: "notificationsForAcceptance",
  data: () => {
    return {
      columns: ["Tytuł", "Opis", "Przycisk", "Data utworzenia", "Użytkownicy"],
      searchUser: "",
      currentNotificationConfirmations: "",
      showModal: false,
      modifyDate: parseDate,
      newNotificationModal: false,
      currentNotificationForConfirmationsDisplay: "",
      title: "",
      buttonText: "",
      description: "",
      notificationUsers: [],
      notificationSearchText: "",
      foundUsers: [],
      timeoutId: "",
      showedUsers: 20,
      notificationsUsersIds: {},
      showedSelectedUsers: 20,
    };
  },
  watch: {
    async notificationSearchText(currVal) {
      await this.fetchUsersForNotification(currVal);
    },
  },
  methods: {
    showMoreUsers() {
      this.showedUsers += 20;
    },
    showMoreUsersSelected() {
      this.showedSelectedUsers += 20;
    },
    ...mapActions([
      "fetchNotificationsForAcceptance",
      "addNotificationForAcceptance",
      "fetchNotificationsForAcceptanceConfirmationsList",
    ]),
    async handleFetch() {
      try {
        await this.fetchNotificationsForAcceptance({
          ...this.meta,
          searchUser: this.searchUser,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono powiadomień`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "Notifications",
        query: {
          searchUser: this.searchUser,
        },
      });
      await this.handleFetch();
    },
    handleReset() {
      this.searchUser = "";
      this.$router.push({
        name: "Notifications",
      });
      this.handleFetch();
    },
    handleCancel() {
      this.currentNotificationConfirmations = [];
      this.currentNotificationForConfirmationsDisplay = "";
      this.showModal = false;
    },
    async handleShowModal(notificationForAcceptance) {
      this.currentNotificationForConfirmationsDisplay =
        notificationForAcceptance.id;
      this.currentNotificationConfirmations = [];
      try {
        await this.fetchNotificationsForAcceptanceConfirmationsList({
          ...this.confirmationsMeta,
          per_page: 10,
          current_page: 1,
          id: notificationForAcceptance.id,
        });
        this.currentNotificationConfirmations = this.confirmations;
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono powiadomień`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }

      this.showModal = true;
    },
    hasMorePagesConfirmations() {
      if (
        this.confirmationsMeta?.last_page <=
        parseInt(this.confirmationsMeta?.current_page ?? 1)
      ) {
        return false;
      }
      return true;
    },
    async showMoreConfirmations() {
      try {
        await this.fetchNotificationsForAcceptanceConfirmationsList({
          per_page: 10,
          current_page: parseInt(this.confirmationsMeta?.current_page ?? 1) + 1,
          id: this.currentNotificationForConfirmationsDisplay,
        });
        const tmp = this.currentNotificationConfirmations.concat(
          this.confirmations
        );
        this.currentNotificationConfirmations = tmp;
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono powiadomień`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    translateNotificationForAcceptanceConfirmationStatus(status) {
      if (status === "CONFIRMED") {
        return "Potwierdził";
      } else {
        return "Nie potwierdził";
      }
    },

    async showNewNotificationModal(notificationSearchText) {
      await this.fetchUsersForNotification(
        this.notificationSearchText,
        notificationSearchText ? true : false
      );
      this.newNotificationModal = true;
    },
    cancelNewNotificationModal() {
      this.newNotificationModal = false;
    },
    deleteNotificationUser(notificationUsersIndex, userId) {
      this.notificationUsers.splice(notificationUsersIndex, 1);
      this.notificationsUsersIds[userId] = false;
    },
    addUserToNotificationUsers(user) {
      if (!this.notificationsUsersIds[user.id]) {
        this.notificationUsers.push({
          id: user.id,
          phone: user.phone,
        });
        this.notificationsUsersIds[user.id] = true;
      }
    },

    async fetchUsersForNotification(searchText) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        const response = await axios.get(
          `admins/users/search?searchUser=${
            searchText || ""
          }&limit=100&status=ACTIVATED`
        );

        this.foundUsers = response.data.data.map((u) => {
          return {
            id: u.id,
            phone: u.phone,
            fullName: u.fullName,
          };
        });
        this.foundUsers = this.foundUsers.filter(
          (u) =>
            u.fullName !== "Użytkownik usunięty" &&
            u.fullName !== "Użytkownik nieaktywny" &&
            !isNaN(u.phone)
        );
      }, 400);
    },

    addAllFoundUsersToNotificationUsers() {
      this.foundUsers.forEach((u) => this.addUserToNotificationUsers(u));
    },

    async saveNewNotification() {
      if (
        !this.title ||
        !this.description ||
        !this.buttonText ||
        !this.notificationUsers ||
        this.notificationUsers.length < 1
      ) {
        this.$toast.open({
          message: `Uzupełnij wszystkie pola: tytuł, treść przycisku, opis oraz wybierz przynajmniej jednego użytkownika`,
          type: "error",
        });
      } else {
        try {
          await this.addNotificationForAcceptance({
            title: this.title,
            description: this.description,
            buttonText: this.buttonText,
            usersIds: this.notificationUsers.map((n) => n.id),
          });
          this.$toast.open({
            message: `Powiadomienie zostało wysłane`,
            type: "success",
          });

          this.cancelNewNotificationModal();
          this.title = "";
          this.description = "";
          this.buttonText = "";
          this.notificationUsers = [];
        } catch (err) {
          if (err.status === 403) {
            this.$toast.open({
              message: `Operacja zabroniona`,
              type: "error",
            });
          } else if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono powiadomień`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },
  async created() {
    const { searchUser: querySearchUser } = this.$router.history.current.query;
    this.searchUser = querySearchUser || "";
    try {
      await this.fetchNotificationsForAcceptance({
        ...this.meta,
        searchUser: this.searchUser,
      });
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono powiadomień`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },
  mounted() {
    this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    window.addEventListener("keypress", this.handleKeyPress);
    const searchUserFromQuery = this.$route.query.s;
    const newNotification = this.$route.params.newNotification;
    if (searchUserFromQuery) {
      this.notificationSearchText = searchUserFromQuery;
      if (newNotification) {
        this.showNewNotificationModal(searchUserFromQuery);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  computed: {
    ...mapGetters({
      notificationsForAcceptance: "getNotificationsForAcceptance",
      confirmations: "getAllNotificationsConfirmations",
      confirmationsMeta: "getAllNotificationsConfirmationsMeta",
      meta: "getNotificationsForAcceptanceMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/variables.scss";

strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}

.notification-input-row {
  width: 100%;
  margin-top: 2rem;
}
.notification-input-row table {
  margin: 0 auto;
}
.notification-input-row label {
  margin-bottom: 2rem;
  width: 30%;
  font-weight: bold;
}
.notification-input-row input,
.notification-input-row textarea {
  width: 50%;
}
.found-users-table {
  margin-top: 2rem !important;
}
.found-users-table tbody tr {
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
}

.found-users-table tbody tr:hover {
  background: $primary;
  color: #fff;
}
.notifications-users {
  display: flex;
}
.notifications-users div:first-child,
.notifications-users div:nth-child(2) {
  width: 50%;
  margin: 0 auto;
}
.added-users {
  font-weight: bold;
  margin-top: 2rem;
}
.added-users-list p {
  margin: 0 !important;
  font-size: 15px;
}
@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
}
</style>
