import authActions from "./authActions";
import usersActions from "./usersActions";
import paymentsActions from "./paymentsActions";
import notificationsActions from "./notificationsActions";
import documentsActions from "./documentsActions";
import chatActions from "./chatActions";
import jobActions from "./jobActions";
import reportActions from "./reportActions";
import notificationsForAcceptance from "./notificationsForAcceptanceActions";
import attractionsActions from "./attractionsActions";
import fileActions from "./fileActions";
import contractorsActions from "./contractorsActions";
import discountActions from "./discountActions";
import advertisementsForApproval from "./advertisementsForApprovalActions";
export default {
  ...authActions,
  ...usersActions,
  ...paymentsActions,
  ...notificationsActions,
  ...documentsActions,
  ...chatActions,
  ...jobActions,
  ...reportActions,
  ...notificationsForAcceptance,
  ...attractionsActions,
  ...fileActions,
  ...contractorsActions,
  ...discountActions,
  ...advertisementsForApproval,
};
