import axios from "../../axios.js";
import router from "../../router";

const login = async ({ commit }, data) => {
  try {
    const response = await axios.post(`auth/login`, data);
    if (response && response.data && response.data.data.accessToken) {
      commit("SET_TOKEN", response.data.data.accessToken);
      localStorage.setItem("FUCHA_TOKEN", response.data.data.accessToken);
      router.push("/users");
      return response.data.status;
    }
  } catch (error) {
    throw { status: error.response.status };
  }
};

const verify = async ({ commit }, data) => {
  try {
    const response = await axios.post("auth/verify", data);
    if (response && response.data && response.data.data.accessToken) {
      commit("SET_TOKEN", response.data.data.accessToken);
      localStorage.setItem("FUCHA_TOKEN", response.data.data.accessToken);
      router.push("/users");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw { status: error.response.status };
  }
};

const logout = ({ commit }) => {
  commit("SET_TOKEN", null), localStorage.removeItem("FUCHA_TOKEN");
  router.push("/login");
};

export default {
  login,
  logout,
  verify,
};
