import axios from "../../axios.js";

const fetchJobs = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}&` : "";
  const status = data.status ? `status=${data.status}` : "";
  const url = `admins/orders?${perPage}${pageNumber}${searchUser}${status}`;
  try {
    const response = await axios.get(url);
    commit("SET_JOBS", response.data.data);
    commit("SET_JOBS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const fetchUserJobs = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}&` : "";
  const status = data.status ? `status=${data.status}` : "";
  const url = `admins/orders/offers?${perPage}${pageNumber}${searchUser}${status}`;
  try {
    const response = await axios.get(url);
    commit("SET_JOBS", response.data.data);
    commit("SET_JOBS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const deleteJob = async (_, data) => {
  try {
    const response = await axios.delete(`advertisements/${data.id}`);
    return response.data.status;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const settleArgument = async (_, data) => {
  try {
    const response = await axios.put(
      `advertisements/${data.id}/unrealizedStatus`,
      {
        status: data.status,
      }
    );
    return response.data.status;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const fetchJobChat = async (_, data) => {
  try {
    const response = await axios.get(`chats/${data.chatId}`);
    return response.data;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const fetchAdvertisementsCategories = async ({ commit }) => {
  try {
    const response = await axios.get("advertisements/categories?limit=1000");

    commit("SET_JOBS_CATEGORIES", response.data.data);
  } catch (e) {
    throw { status: e.response.status };
  }
};

export default {
  fetchJobs,
  fetchUserJobs,
  deleteJob,
  settleArgument,
  fetchJobChat,
  fetchAdvertisementsCategories,
};
