<template>
  <header v-if="token && !noHeader" class="mb-5 shadow position-sticky top-0">
    <nav class="navbar navbar-expand-md">
      <div class="py-2 container">
        <div class="rounded mx-auto mx-sm-0 mb-2 mb-sm-0">
          <img class="logo" src="../assets/fucha_logo.png" />
        </div>
        <div>
          <button
            class="btn btn-sm route mx-lg-2 mx-2"
            v-for="route in routes"
            :key="route.name"
            @click="changeView(route.to)"
          >
            {{ route.name }}
          </button>
        </div>
        <div class="d-flex justify-content-center logout d-sm-inline-block">
          <button class="btn btn-sm route" @click="handleLogout()">
            Wyloguj
          </button>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => {
    return {
      routes: [
        {
          to: "/users",
          name: "Użytkownicy",
        },
        {
          to: "/zlecenia",
          name: "Zlecenia",
        },
        {
          to: "/contractors",
          name: "Ogłoszenia",
        },
        {
          to: "/atrakcje",
          name: "Atrakcje",
        },
        {
          to: "/atrakcje/zgloszenia",
          name: "Zgłoszenia atrakcji",
        },
        {
          to: "/wyplaty",
          name: "Wypłaty",
        },
        {
          to: "/discounts",
          name: "Kody rabatowe",
        },
        {
          to: "/czaty",
          name: "Czaty",
        },
        {
          to: "/transakcje",
          name: "Transakcje",
        },
        {
          to: "/zgloszenia",
          name: "Zgłoszenia",
        },
        {
          to: "/powiadomienia",
          name: "Powiadomienia",
        },

        {
          to: "/reports",
          name: "Raporty",
        },
        {
          to: "/dodawanie-zlecen",
          name: "Dodawanie zleceń CSV",
        },
        {
          to: "/zlecenia-do-zaakceptowania",
          name: "Zlecenia do zaakceptowania",
        },
      ],
      noHeader: false,
    };
  },

  methods: {
    changeView(view) {
      this.$router.push(`${view}`).catch(() => {});
    },
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
    },
  },

  watch: {
    $route: {
      handler: function (route) {
        if (
          route.name === "PaymentProcessing" ||
          route.name === "ListOfDocuments"
        ) {
          this.noHeader = true;
        } else {
          this.noHeader = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      token: "getToken",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "/src/styles/variables.scss";

header {
  z-index: 99;
}

.navbar {
  background-color: rgb(255, 255, 255) !important;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  color: rgb(0, 0, 0);
  position: relative;
  text-align: center;
  /* -webkit-box-shadow: 0px 2px 40px 1px #95693d;
  -moz-box-shadow: 0px 2px 40px 1px rgba(149, 105, 61, 1);
  box-shadow: 0px 2px 40px 1px rgba(149, 105, 61, 1); */
}
// .route {
//   color: rgb(0, 0, 0);
//   margin: 0 20px 0 20px;
// }
.route {
  font-weight: 700;
}

.route:hover {
  color: $primary;
}

nav div,
nav img {
  background: none;
  // min-height: 55px;
  display: flex;
  align-items: center;
  // height: 100px;
}

img.logo {
  height: 40px;
}

.logout {
  width: fit-content;
}

@media (max-width: 1380px) {
  // .navbar {
  //   height: 200px;
  // }
  .logout {
    width: 100%;
  }

  nav div,
  nav img {
    display: block;
  }
}
</style>
