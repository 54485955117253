<template>
  <div class="container table-responsive">
    <p class="h1 fw-light mb-2 mb-sm-4">Lista zgłoszeń atrakcji</p>
    <table
      class="table align-middle"
      v-if="reports && reports.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>
            {{ report.reportingUser.fullName || "" }}
            <br />
            <b-button
              v-if="
                report.reportingUser &&
                report.reportingUser.fullName &&
                report.reportingUser.fullName !== 'Użytkownik usunięty' &&
                report.reportingUser.fullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: report.reportingUser.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>{{ report.description || "" }}</td>
          <td>{{ report.createdAt ? report.createdAt.slice(0, 10) : "-" }}</td>
          <td
            @click="
              router.push(
                `/atrakcje?searchAttraction=${report.reportedAttraction.title}`
              )
            "
            class="link"
          >
            {{ report.reportedAttraction.title || "" }}
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak zgłoszeń'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchAttractionReports`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="status"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { parseDate } from "../utils/helpers";
import router from "../router";
export default {
  components: { EmptyListAlert, Pagination },
  name: "AttractionReports",
  data: () => {
    return {
      columns: [
        "Osoba zgłaszająca",
        "Opis",
        "Data zgłoszenia",
        "Zgłoszona atrakcja",
      ],
      searchUser: "",
      currentJob: "",
      showModal: false,
      modifyDate: parseDate,
      router: router,
    };
  },
  methods: {
    ...mapActions(["fetchAttractionReports"]),
    async handleFetch() {
      try {
        await this.fetchAttractionReports(...this.meta);
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zgłoszeń`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },
  async created() {
    try {
      await this.fetchAttractionReports(this.meta);
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zgłoszeń`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
    }
  },
  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      reports: "getAllAttractionReports",
      meta: "getAttractionsReportsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style scoped>
strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
}
</style>
