import axios from "../../axios.js";

const fetchNotificationsForAcceptance = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}` : "";
  try {
    const response = await axios.get(
      `admins/notificationsForAcceptance?${perPage}${pageNumber}${searchUser}`
    );
    commit("SET_NOTIFICATIONS_FOR_ACCEPTANCE", response.data.data);
    commit("SET_NOTIFICATIONS_FOR_ACCEPTANCE_META", response.data.meta);
  } catch (e) {
    throw { status: e.response?.status };
  }
};
const fetchNotificationsForAcceptanceConfirmationsList = async (
  { commit },
  data
) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  try {
    const response = await axios.get(
      `admins/notificationsForAcceptance/${data.id}/confirmations?${perPage}${pageNumber}`
    );
    commit("SET_NOTIFICATIONS_CONFIRMATIONS", response.data.data);
    commit("SET_NOTIFICATIONS_CONFIRMATIONS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response?.status };
  }
};

const addNotificationForAcceptance = async ({ dispatch }, data) => {
  const { title, description, buttonText, usersIds } = data;
  await axios.post(`admins/notificationsForAcceptance`, {
    title,
    description,
    buttonText,
    usersIds,
  });
  dispatch("fetchNotificationsForAcceptance", {
    per_page: 10,
    current_page: 1,
  });
};

export default {
  fetchNotificationsForAcceptance,
  addNotificationForAcceptance,
  fetchNotificationsForAcceptanceConfirmationsList,
};
