import axios from '../../axios.js';

const fetchNotifications = async ({ commit }, data) => {
  const perPage = data.perPage ? `onPage=${data.perPage}&` : '';
  const pageNumber = data.pageNumber ? `pageNumber=${data.pageNumber}` : '';
  const response = await axios.get(
    `api/admin/notifications?${perPage}${pageNumber}`
  );
  commit('SET_NOTIFICATIONS', response.data.data);
};

export default {
  fetchNotifications,
};
