<template>
  <div class="container table-responsive">
    <div
      v-bind:class="showModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="showModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${showModal ? 'user-modal' : ''}`"
      >
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
          "
          @click="handleCancel($event)"
        >
          <span>x</span>
        </div>
        <div
          style="
            padding: 2rem;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <h4>Szczegóły zlecenia</h4>
          <div class="modal-content">
            <strong>Tytuł zlecenia:</strong>
            <p class="info">{{ currentJob.title || "-" }}</p>
            <strong>Data utworzenia:</strong>
            <p class="info">
              {{
                currentJob.createdAt ? modifyDate(currentJob.createdAt) : "-"
              }}
            </p>
            <strong>Data ostatniej modyfikacji:</strong>
            <p class="info">
              {{
                currentJob.modifiedAt ? modifyDate(currentJob.modifiedAt) : "-"
              }}
            </p>
            <strong>Adres:</strong>
            <p class="info">
              {{
                `${currentJob.street || ""} ${currentJob.zipCode || ""} ${
                  currentJob.city || ""
                }`
              }}
              {{
                !currentJob.street && !currentJob.zipCode && !currentJob.city
                  ? "-"
                  : null
              }}
            </p>
            <strong>Status:</strong>
            <p class="info">
              {{ currentJob.status ? jobStatuses[currentJob.status] : "-" }}
            </p>
            <strong>Kwota:</strong>
            <p class="info">{{ currentJob.renumeration || "-" }}</p>
            <strong>Opis zlecenia:</strong>
            <p class="info">{{ currentJob.description || "-" }}</p>
            <strong>Pozostały czas zlecenia:</strong>
            <p class="info">
              {{
                !currentJob.expirationTimeHours &&
                !currentJob.expirationTimeMinutes
                  ? "-"
                  : `${
                      currentJob.expirationTimeHours
                        ? `${currentJob.expirationTimeHours}g`
                        : ""
                    } ${currentJob.expirationTimeMinutes || 0}m`
              }}
            </p>
            <strong>Termin realizacji:</strong>
            <p class="info">
              {{
                currentJob.offerCompletionDate
                  ? modifyDate(currentJob.offerCompletionDate)
                  : "-"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="h1 fw-light mb-2 mb-sm-4">Lista zgłoszeń</p>
    <div class="filter-wrapper">
      <div class="filter-actions-wrapper">
        <h2>Filtr użytkowników</h2>
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="reports && reports.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>
            {{ report.reportingUser.fullName || "" }}
            <br />
            <b-button
              v-if="
                report.reportingUser &&
                report.reportingUser.fullName &&
                report.reportingUser.fullName !== 'Użytkownik usunięty' &&
                report.reportingUser.fullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: report.reportingUser.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>{{ report.description || "" }}</td>
          <td>{{ report.createdAt ? report.createdAt.slice(0, 10) : "-" }}</td>
          <td
            class="link"
            @click="handleShowModal(report.reportedAdvertisement, report)"
          >
            {{ report.reportedAdvertisement.description || "-" }}
          </td>
          <td>
            <span
              class="link"
              @click="
                $router.push(`/users/${report.reportedAdvertisement.user.id}`)
              "
              >{{ report.reportedAdvertisement.user.fullName || "-" }}</span
            >

            <br />
            <b-button
              v-if="
                report.reportedAdvertisement.user &&
                report.reportedAdvertisement &&
                report.reportedAdvertisement.user.fullName &&
                report.reportedAdvertisement.user.fullName !==
                  'Użytkownik usunięty' &&
                report.reportedAdvertisement.user.fullName !==
                  'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: report.reportedAdvertisement.user.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert
      v-else
      :content="'Brak próśb o wpłaty'"
    ></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchReports`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { JOB_STATUSES } from "../utils/statuses";
import { parseDate } from "../utils/helpers";

export default {
  components: { EmptyListAlert, Pagination },
  name: "Reports",
  data: () => {
    return {
      columns: [
        "Osoba zgłaszająca",
        "Opis",
        "Data zgłoszenia",
        "Zgłaszane zlecenie",
        "Osoba zlecająca",
      ],
      searchUser: "",
      currentJob: "",
      showModal: false,
      jobStatuses: { ...JOB_STATUSES },
      modifyDate: parseDate,
    };
  },
  methods: {
    ...mapActions(["fetchReports"]),
    async handleFetch() {
      try {
        await this.fetchReports({ ...this.meta, searchUser: this.searchUser });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zgłoszeń`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "Reports",
        query: {
          searchUser: this.searchUser,
        },
      });
      await this.handleFetch();
    },
    handleReset() {
      this.searchUser = "";
      this.$router.push({
        name: "Reports",
      });
      this.handleFetch();
    },
    handleCancel() {
      this.currentJob = "";
      this.showModal = false;
    },
    handleShowModal(advertisement, report) {
      this.currentJob = advertisement;
      this.currentJob.offerCompletionDate = report.offerCompletionDate;

      this.showModal = true;
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },
  async created() {
    const { searchUser: querySearchUser } = this.$router.history.current.query;
    try {
      await this.fetchReports({
        per_page: 10,
        current_page: this.meta.current_page || this.$route.query.page || 1,
        searchUser: querySearchUser || "",
      });
      this.searchUser = querySearchUser || "";
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono zgłoszeń`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },
  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  computed: {
    ...mapGetters({ reports: "getReports", meta: "getReportsMeta" }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped>
strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
}
</style>
