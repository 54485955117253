<template>
  <div id="app">
    <Header />
    <router-view class="container-fluid mx-auto pb-3"></router-view>
  </div>
</template>

<script>
import Header from "@/layouts/Header.vue";

export default {
  name: "App",
  components: { Header },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.quill-editor p {
  word-break: normal !important;
}
</style>
