<template>
  <gmap-autocomplete
    @place_changed="handlePlaceChanged"
    class="introInput"
    id="autocomplete"
    @input="debouncedSearch($event)"
  >
    <template v-slot:input="slotProps">
      <v-text-field
        outlined
        prepend-inner-icon="place"
        placeholder="Location Of Event"
        ref="input"
        v-on:listeners="slotProps.listeners"
        v-on:attrs="slotProps.attrs"
      >
      </v-text-field>
    </template>
  </gmap-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      lastValue: "",
    };
  },
  mounted() {
    // Dodajemy jeden listener przy montowaniu komponentu
    const autocompleteInput = document.getElementById("autocomplete");
    if (autocompleteInput) {
      autocompleteInput.addEventListener("input", this.handleInput);
    }
  },
  beforeUnmount() {
    // Usuwamy listener przy odmontowywaniu komponentu
    const autocompleteInput = document.getElementById("autocomplete");
    if (autocompleteInput) {
      autocompleteInput.removeEventListener("input", this.handleInput);
    }
  },

  emits: ["newPlace"],
  methods: {
    handleInput(event) {
      this.debouncedSearch(event);
    },
    debouncedSearch(event) {
      event.stopImmediatePropagation();
      const value = event.target.value;

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        if (value !== this.lastValue) {
          this.lastValue = value;

          // Odświeżamy komponent gmap-autocomplete
          this.$nextTick(() => {
            const autocompleteInput = document.getElementById("autocomplete");
            if (autocompleteInput) {
              autocompleteInput.blur();
              autocompleteInput.focus();
            }
          });
        }
      }, 1000);
    },

    handlePlaceChanged(place) {
      const finalPlace = {
        address: "",
        street: "",
        zipCode: "",
        countryShortName: "",
        country: "",
        city: "",
        latitude: place.geometry
          ? place.geometry.location.lat().toString()
          : null,
        longitude: place.geometry
          ? place.geometry.location.lng().toString()
          : null,
      };
      place.address_components.forEach((component) => {
        if (component.types.includes("postal_code")) {
          finalPlace.zipCode = component.long_name;
        }
        if (component.types.includes("locality")) {
          finalPlace.city = component.long_name;
        }
        if (component.types.includes("country")) {
          finalPlace.country = component.long_name;
          finalPlace.countryShortName = component.short_name;
        }
        if (component.types.includes("route")) {
          if (component.long_name) {
            finalPlace.street = component.long_name;
          }
        }
        if (component.types.includes("street_number")) {
          if (component.long_name) {
            finalPlace.address = component.long_name;
          }
        }
      });
      this.$emit("newPlace", finalPlace);
    },
  },
};
</script>

<style scoped>
.introInput {
  padding: 5px !important;
  max-height: 30px;
  border: 1px solid #8f8f9d;
  width: 50%;
}
</style>
