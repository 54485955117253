const state = {
  chats: {
    chats: [],
    pagination: {},
  },
  messages: {
    messages: [],
    pagination: {},
  },
  usersChats: [],
  usersChatsMeta: {},
};

const mutations = {
  SET_CHATS(state, chats) {
    state.chats = chats;
  },

  SET_MESSAGES(state, messages) {
    state.messages = messages;
  },

  SET_USERS_CHATS(state, chats) {
    state.usersChats = chats;
  },

  SET_USERS_CHATS_META(state, meta) {
    state.usersChatsMeta = meta;
  },
};

const getters = {
  getChats: (state) => state.chats,
  getMessages: (state) => state.messages,
  getUsersChats: (state) => state.usersChats,
  getUsersChatsMeta: (state) => state.usersChatsMeta,
};

export default {
  state,
  mutations,
  getters,
};
