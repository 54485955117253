<template>
  <div class="container table-responsive">
    <div
      v-bind:class="openDeleteModal || openEditNoteModal ? 'd-flex' : 'd-none'"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div
        v-if="openEditNoteModal"
        style="
          padding: 2rem;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          border-radius: 8px;
        "
      >
        <div
          :style="`
            position: absolute;
            top: -2px;
            right: -2px;
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            line-height: 18px;
            border-radius: 999px;
            font-weight: 700;
            z-index: 9999;`"
          @click="handleCancel($event)"
        >
          <span>x</span>
        </div>
        <strong style="font-size: 20px">Edycja notatki</strong>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          v-model="noteValue"
          style="resize: none; margin: 20px 0"
        ></textarea>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleEditNote()"
            >Edytuj
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleCancel($event)"
            >anuluj
          </b-button>
        </div>
      </div>
      <div
        v-else-if="!userAvatar"
        style="
          padding: 2rem;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        {{
          userToDeleteId
            ? "Usunąć użytkownika?"
            : userToBlockOrUnblockId &&
              (statusValue === "BLOCKED" || blockOrUnblockStatus === "UNBLOCK")
            ? "Czy chcesz odblokować użytkownika"
            : "Czy chcesz zablokować użytkownika"
        }}
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="userToDeleteId ? handleDelete() : handleBlockUser()"
            >{{
              userToDeleteId
                ? "Usuń"
                : userToBlockOrUnblockId &&
                  (statusValue === "BLOCKED" ||
                    blockOrUnblockStatus === "UNBLOCK")
                ? "Odblokuj"
                : "Zablokuj"
            }}
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >anuluj
          </b-button>
        </div>
      </div>
      <div v-else style="position: relative" class="modal-wrapper">
        <img :src="userAvatar" alt="User avatar" class="avatar-img" />
        <div
          style="
            position: absolute;
            top: -20px;
            right: 50%;
            transform: translateX(50%);
            background: red;
            color: white;
            text-align: center;
            cursor: pointer;
            padding: 5px;
            border-radius: 5px;
            font-weight: 700;
          "
          @click="handleCancel($event)"
        >
          <span>Zamknij zdjęcie</span>
        </div>
      </div>
    </div>
    <p class="h1 fw-light mb-2 mb-sm-4">Użytkownicy</p>
    <div class="filter-wrapper">
      <h2>Filtr użytkowników</h2>
      <div class="filter-actions-wrapper">
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
      <h3 style="margin-top: 20px">Filtr statusów</h3>
      <b-button
        style="width: 80px; margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === '' }"
        @click="handleFetchWithStatus('')"
        >Wszyscy
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'ACTIVATED' }"
        @click="handleFetchWithStatus('ACTIVATED')"
        >Aktywni
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'WAITING_FOR_ACCEPTANCE' }"
        @click="handleFetchWithStatus('WAITING_FOR_ACCEPTANCE')"
        >W trakcie logowania
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'BLOCKED' }"
        @click="handleFetchWithStatus('BLOCKED')"
        >Zablokowani
      </b-button>
    </div>
    <table
      class="table align-middle"
      v-if="users && users.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td style="position: relative">
            <div class="action-column">
              <div
                @click="handleShowModal(user.id, user.email)"
                class="danger-button"
              >
                <span>x</span>
                <span class="hint">Usuń użytkownika</span>
              </div>
              <div
                v-if="statusValue === 'BLOCKED' || user.status === 'BLOCKED'"
                @click="handleShowBlockModal(user.id, 'UNBLOCK')"
              >
                <img src="../assets/accept.png" alt="Block user" />
                <span class="hint">Odblokuj użytkownika</span>
              </div>
              <div v-else @click="handleShowBlockModal(user.id, 'BLOCK')">
                <img src="../assets/minus-circle.svg" alt="Block user" />
                <span class="hint">Zablokuj użytkownika</span>
              </div>
            </div>
          </td>
          <td>
            {{ user.fullName || "-" }}
            <br />
            <b-button
              v-if="
                user.fullName !== 'Użytkowik usunięty' &&
                user.fullName !== 'Użytkownik nieaktywny'
              "
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: user.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>{{ user.phone || "-" }}</td>
          <td>
            {{ formatAddress(user.street, user.address, user.city) }}
          </td>
          <td>{{ user.status ? userStatuses[user.status] : "-" }}</td>
          <td>{{ user.description || "-" }}</td>
          <td>{{ user.birthDate || "-" }}</td>
          <td>{{ user.rating === null ? "-" : user.rating }}</td>
          <td>
            <b-button
              v-if="user.avatar"
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="handleShowAvatar(user.avatar.id)"
              >Zdjęcie profilowe</b-button
            >
            <b-button
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="$router.push(`/transakcje/${user.id}`)"
              >Transakcje</b-button
            >
            <b-button
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="$router.push(`/zlecenia/${user.id}`)"
              >Zlecenia</b-button
            >
            <b-button
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="handleShowEditNoteModal(user.id, user.note)"
              >Zobacz notatkę</b-button
            >
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak użytkowników'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchUsers`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="statusValue"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { USER_STATUSES } from "../utils/statuses";

export default {
  name: "Users",

  components: {
    Pagination,
    EmptyListAlert,
  },

  data: () => {
    return {
      columns: [
        "",
        "Imię i nazwisko",
        "Numer telefonu",
        "Adres",
        "Status",
        "Opis",
        "Data urodzenia",
        "Średnia ocen",
        "Akcje",
      ],
      statusValue: "",
      openDeleteModal: false,
      userToDelete: "",
      userToDeleteId: "",
      userToBlockOrUnblockId: "",
      userStatuses: { ...USER_STATUSES },
      resetMeta: false,
      userAvatar: "",
      searchUser: "",
      blockOrUnblockStatus: "BLOCK",
      noteValue: "",
      userIdForEditNote: "",
      openEditNoteModal: false,
    };
  },

  methods: {
    ...mapActions([
      "fetchUsers",
      "deleteUser",
      "blockOrUnblockUser",
      "fetchUserAvatar",
      "editUserNote",
    ]),
    formatAddress(street, buildingNumber, city) {
      if (!city) {
        return "-";
      }
      return `${city} ${
        street ? `${street} ${buildingNumber ? buildingNumber : ""}` : ""
      }`;
    },
    async handleFetch() {
      try {
        await this.fetchUsers({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          searchUser: this.searchUser,
          status: this.statusValue,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Użytkownik nieznaleziony`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleBlockUser() {
      try {
        await this.blockOrUnblockUser({
          userId: this.userToBlockOrUnblockId,
          actionType:
            this.statusValue === "BLOCKED" ||
            this.blockOrUnblockStatus === "UNBLOCK"
              ? "unblock"
              : "block",
        });
        this.userToBlockOrUnblockId = "";
        await this.handleFetch();
        this.$toast.open({
          message:
            this.statusValue === "BLOCKED"
              ? "Odblokowano użytkownika"
              : "Zablokowano użytkownika",
          type: "success",
        });
        this.openDeleteModal = false;
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Użytkownik nieznaleziony`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleShowAvatar(avatarId) {
      try {
        const response = await this.fetchUserAvatar(avatarId);
        if (response) {
          this.userAvatar = response;
          this.openDeleteModal = true;
        }
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zdjęcia`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleReset() {
      this.searchUser = "";
      this.statusValue = "";
      this.resetMeta = true;
      this.$router.push({
        name: "Users",
      });
      this.handleFetch();
      this.resetMeta = false;
    },
    handleCancel() {
      this.openDeleteModal = false;
      this.openEditNoteModal = false;
      this.userToDelete = "";
      this.userToDeleteId = "";
      this.userToBlockOrUnblockId = "";
      this.userAvatar = "";
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "Users",
        query: {
          status: this.statusValue,
          searchUser: this.searchUser,
        },
      });
      this.resetMeta = true;
      await this.handleFetch();
      this.resetMeta = false;
    },
    handleShowModal(id, name) {
      this.userToDelete = name;
      this.userToDeleteId = id;
      this.openDeleteModal = true;
    },
    handleShowBlockModal(id, status) {
      this.userToBlockOrUnblockId = id;
      this.openDeleteModal = true;
      this.blockOrUnblockStatus = status;
    },
    handleShowEditNoteModal(id, note) {
      this.openEditNoteModal = true;
      this.noteValue = note;
      this.userIdForEditNote = id;
    },
    async handleDelete() {
      try {
        await this.deleteUser({
          id: this.userToDeleteId,
        });
        this.userToDelete = "";
        this.userToDeleteId = "";
        await this.handleFetch();
        this.$toast.open({
          message: `Usunięto użytkownika`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono użytkownika`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
      this.openDeleteModal = false;
    },
    handleFetchWithStatus(status) {
      this.statusValue = status;
      this.handleUsersFilter();
      this.resetMeta = true;
      //this.handleFetch();
      this.resetMeta = false;
    },
    async handleEditNote() {
      try {
        if (this.noteValue.length < 4001) {
          await this.editUserNote({
            id: this.userIdForEditNote,
            note: this.noteValue,
          });
          this.openEditNoteModal = false;
          this.userIdForEditNote = "";
          this.noteValue = "";
          await this.handleFetch();
          this.$toast.open({
            message: `Zedytowano notatkę`,
            type: "success",
          });
        } else {
          this.$toast.open({
            message: `Notatka może mieć maksymalnie 4000 znaków`,
            type: "error",
          });
        }
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono użytkownika`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },

  async created() {
    const { status: queryStatus, searchUser: querySearchUser } =
      this.$router.history.current.query;
    try {
      await this.fetchUsers({
        per_page: 10,
        current_page: this.meta.current_page || this.$route.query.page || 1,
        searchUser: this.$route.params.userId || querySearchUser || "",
        status: queryStatus || "",
      });
      this.searchUser = querySearchUser || "";
      this.statusValue = queryStatus || "";
      if (this.$route.params.userId && this.users.length > 0) {
        this.searchUser = this.users[0].fullName || "";
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      users: "getAllUsers",
      meta: "getMeta",
    }),

    // isUpperPaginationVisible() {
    //   return this.users.users && this.users.pagination.total > 20;
    // },

    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  border-bottom-width: 1px;
}

td {
  border-bottom-width: 0px;
}

.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}

.avatar-img {
  display: block;
  width: 50vw;
  height: 70vh;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 75vw;
  }
}
</style>
