import axios from "../../axios.js";

const fetchContractors = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `searchUser=${data.searchUser}` : "";
  const category = data.category ? `category=${data.category}` : "";
  try {
    const response = await axios.get(
      `contractors?${perPage}${pageNumber}${searchUser}${category}`
    );
    commit("SET_CONTRACTORS", response.data.data);
    commit("SET_CONTRACTORS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response?.status };
  }
};
const fetchContractorsCategories = async ({ commit }) => {
  const perPage = `limit=9999999999`;
  try {
    const response = await axios.get(`contractorsCategories?${perPage}`);
    commit("SET_CONTRACTORS_CATEGORIES", response.data.data);
  } catch (e) {
    throw { status: e.response.status };
  }
};
const editContractor = async ({ dispatch }, body) => {
  const { id, ...obj } = body;
  await axios.put(`contractors/${id}`, obj);
  dispatch("fetchContractors", { per_page: 10, current_page: 1 });
};
const deleteContractor = async ({ dispatch }, id) => {
  await axios.delete(`contractors/${id}`);
  dispatch("fetchContractors", { per_page: 10, current_page: 1 });
};
export default {
  fetchContractors,
  fetchContractorsCategories,
  editContractor,
  deleteContractor,
};
