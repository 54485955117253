<template>
  <div class="container table-responsive">
    <!-- ADDING MODAL -->
    <div
      v-bind:class="newAttractionModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="newAttractionModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${newAttractionModal ? 'user-modal' : ''}`"
      >
        <div class="delete-button" @click="cancelnewAttractionModal()">
          <span>x</span>
        </div>
        <div class="modal-common-content">
          <h4>Dodawanie nowej atrakcji</h4>
          <div class="modal-content">
            <div class="notification-input-row">
              <label>Nazwa</label>
              <input v-model="title" maxlength="255" type="text" />

              <label>Opis</label>
              <textarea v-model="description" maxlength="8000" />

              <label>Kategoria</label>
              <select v-model="chosenAttractionCategory">
                <option
                  :value="cat.id"
                  v-for="cat in allAttractionsCategories"
                  :key="cat.id"
                >
                  {{ cat.title }}
                </option>
              </select>
              <br />
              <div style="display: flex; justify-content: center">
                <label>Adres:</label>
                <div class="" v-if="!changeAddress && currentAttraction !== ''">
                  {{
                    `${place.street} ${place.address} ${place.zipCode} ${place.city}`
                  }}
                  <span
                    @click="(changeAddress = true), (place = {})"
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      font-weight: 800;
                    "
                    >Zmień adres</span
                  >
                </div>
                <GSearch v-else @newPlace="handleNewPlace"></GSearch>
              </div>
              <div style="display: flex; justify-content: center">
                <label>Data rozpoczęcia:</label>
                <Datepicker
                  style="width: 50%"
                  :language="pl"
                  v-model="dateFrom"
                  :value="dateFrom"
                ></Datepicker>
              </div>
              <div style="display: flex; justify-content: center">
                <label>Data zakończenia:</label>
                <Datepicker
                  style="width: 50%"
                  :language="pl"
                  v-model="dateTo"
                  :value="dateTo"
                ></Datepicker>
              </div>
              <label>Źródło</label>
              <input v-model="source" maxlength="999" type="text" />
              <div
                v-if="
                  attractionsImagesPreview.length + fetchedImages.length < 5
                "
                style="display: flex; justify-content: center"
              >
                <label for="attractionFilesInput">Zdjęcia atrakcji</label>
                <input
                  type="file"
                  id="attractionFilesInput"
                  accept="image/png, image/jpeg, image/jpg"
                  @change="(e) => handleAddAttractionFile(e.target.files[0])"
                />
              </div>
              <div class="images-wrapper">
                <div
                  v-for="(image, index) in attractionsImagesPreview"
                  :key="index"
                  class="image-wrapper"
                >
                  <div
                    class="delete-button"
                    @click="handleRemoveAttractionFile(index)"
                  >
                    <span>x</span>
                  </div>
                  <img :src="image" alt="" />
                </div>
                <div
                  v-for="(image, index) in fetchedImages"
                  :key="index"
                  class="image-wrapper"
                >
                  <div
                    class="delete-button"
                    @click="handleDeleteAttractionFile(image.imageId)"
                  >
                    <span>x</span>
                  </div>
                  <img :src="image.file" alt="" />
                </div>
              </div>
              <br />
              <b-button
                style="font-weight: 700; width: 100%; margin-bottom: 10px"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                @click="saveNewAttraction"
              >
                {{
                  currentAttraction !== "" ? "Zapisz zmiany" : "Dodaj atrakcję"
                }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DELETE CONFIRM MODAL -->
    <div
      v-bind:class="showModal ? 'd-flex' : 'd-none'"
      class="modal-common-wrapper"
    >
      <div
        v-if="showModal"
        class="modal-wrapper"
        style="position: relative"
        :class="`${newAttractionModal ? 'confirmation-modal' : ''}`"
      >
        <div class="delete-button" @click="handleCancel()">
          <span>x</span>
        </div>
        <div class="modal-common-content">
          <h4>Czy na pewno chcesz usunąć atrakcję?</h4>
          <div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                margin: 40px auto;
              "
            >
              <b-button
                style="width: 300px; margin-left: 10px; font-weight: 700"
                class="btn btn-sm ml-1"
                variant="outline-secondary"
                id="filter-button"
                @click="handleDeleteAttraction"
                >Tak
              </b-button>
              <b-button
                style="width: 300px; margin-left: 10px; font-weight: 700"
                class="btn btn-sm ml-1"
                variant="outline-primary"
                @click="handleCancel"
                >Nie
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="h1 fw-light mb-2 mb-sm-4">Lista atrakcji</p>
    <b-button
      style="font-weight: 700"
      class="btn btn-sm ml-1"
      variant="outline-primary"
      id="filter-button"
      @click="showNewAttractionModal"
    >
      <i class="fa fa-plus" style="margin-right: 0.5rem" aria-hidden="true"></i
      >Dodaj nową atrakcję
    </b-button>

    <div class="filter-wrapper">
      <div class="filter-actions-wrapper">
        <h2>Filtr po nazwie</h2>
        <input type="text" v-model="searchAttraction" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleAttractionsFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div>

    <table
      class="table align-middle"
      v-if="allAttractions && allAttractions.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="attraction in allAttractions" :key="attraction.id">
          <td style="position: relative">
            <div class="action-column">
              <div
                @click="handleShowModal(attraction.id)"
                class="danger-button"
              >
                <span>x</span>
                <span class="hint">Usuń atrakcje</span>
              </div>
              <div @click="handleStartAttractionEdit(attraction)">
                <img src="../assets/edit.svg" alt="Block user" />
                <span class="hint">Edytuj atrakcję</span>
              </div>
              <div
                v-if="attraction.setPermanentVisibilityByAdmin"
                @click="
                  () => handleToggleAttractionVisibility(attraction.id, false)
                "
              >
                <img src="../assets/eye.svg" alt="Block user" />
                <span class="hint">Zawsze widoczna</span>
              </div>
              <div
                v-else
                @click="
                  () => handleToggleAttractionVisibility(attraction.id, true)
                "
              >
                <img src="../assets/eye-off.svg" alt="Block user" />
                <span class="hint"
                  >Widoczna, jeśli użytkownik posiada aktywną subskrypcję</span
                >
              </div>
            </div>
          </td>
          <td>{{ attraction.title || "" }}</td>
          <td>{{ attraction.category.title || "" }}</td>
          <td>
            {{
              `${attraction.street} ${attraction.address}, ${attraction.zipCode} ${attraction.city}`
            }}
          </td>
          <td>
            {{ `Szerokość: ${attraction.lat} Długość: ${attraction.lng}` }}
          </td>
          <td v-if="formattedDateAsNumbers">
            {{ formattedDateAsNumbers(attraction.createdAt) }}
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak atrakcji'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchAttractions`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="searchAttraction"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import Datepicker from "vuejs-datepicker";
import { pl } from "vuejs-datepicker/dist/locale";
import GSearch from "@/components/GSearch";

export default {
  components: { EmptyListAlert, Pagination, Datepicker, GSearch },
  name: "Attractions",
  data: () => {
    return {
      columns: [
        "",
        "Nazwa",
        "Kategoria",
        "Adres",
        "Wsp. geograficzne",
        "Data utworzenia",
      ],
      searchAttraction: "",
      showModal: false,
      newAttractionModal: false,
      deleteAttractionModal: false,
      title: "",
      description: "",
      attractionSearchText: "",
      chosenAttractionCategory: "",
      dateFrom: "",
      dateTo: "",
      source: "",
      attractionImagesToAdd: [],
      attractionsImagesPreview: [],
      fetchedImages: [],
      imagesToDelete: [],
      changeAddress: false,
      pl: pl,
      place: {},
      currentAttraction: "",
    };
  },

  methods: {
    ...mapActions([
      "fetchAttractions",
      "fetchAttractionCategories",
      "addAttraction",
      "deleteAttraction",
      "fetchAttraction",
      "editAttraction",
      "toggleAttractionVisibility",
      "addImage",
      "getImage",
      "deleteImage",
    ]),

    async handleFetch() {
      try {
        await this.fetchAttractions({
          ...this.meta,
          searchAttraction: this.searchAttraction,
        });
      } catch (err) {
        console.log(err);

        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono atrakcji`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },

    //HANDLING FILTERS
    async handleAttractionsFilter() {
      this.$router.push({
        name: "Attractions",
        query: {
          searchAttraction: this.searchAttraction,
        },
      });
      await this.handleFetch();
    },

    //RESETING FILTERS
    handleReset() {
      this.searchAttraction = "";
      this.$router.push({
        name: "Attractions",
      });
      this.handleFetch();
    },

    //NEW PLACE FROM AUTOCOMPLETE
    handleNewPlace(place) {
      this.place = place;
    },

    //DELETING CONFIRMATION MODALS
    handleCancel() {
      this.currentAttraction = "";
      this.showModal = false;
    },
    handleShowModal(id) {
      this.currentAttraction = id;
      this.showModal = true;
    },

    handleAddAttractionFile(file) {
      if (this.attractionsImagesPreview.length > 4) {
        this.$toast.open({
          message: "Możesz dodać maksymalnie 5 zdjęć do atrakcji",
          type: "error",
        });
        return;
      }
      if (
        file.name.endsWith(".jpeg") ||
        file.name.endsWith(".png") ||
        file.name.endsWith(".jpg")
      ) {
        this.attractionImagesToAdd.push(file);
        this.attractionsImagesPreview.push(URL.createObjectURL(file));
      } else {
        this.$toast.open({
          message: "Dostępne rozszerzenia plików to jpg, png, jpeg",
          type: "error",
        });
      }
    },

    handleRemoveAttractionFile(index) {
      this.attractionImagesToAdd = this.attractionImagesToAdd.toSpliced(
        index,
        1
      );
      this.attractionsImagesPreview = this.attractionsImagesPreview.toSpliced(
        index,
        1
      );
    },

    handleDeleteAttractionFile(id) {
      this.imagesToDelete.push(id);
      this.fetchedImages = this.fetchedImages.filter(
        (image) => image.imageId !== id
      );
    },

    //ADDING NEW ATTRACTION MODALS
    showNewAttractionModal() {
      this.title = "";
      this.description = "";
      this.chosenAttractionCategory = "";
      this.dateFrom = "";
      this.dateTo = "";
      this.place = {};
      this.currentAttraction = "";
      this.newAttractionModal = true;
      this.attractionImagesToAdd = [];
      this.attractionsImagesPreview = [];
      this.fetchedImages = [];
      this.imagesToDelete = [];
      this.source = "";
    },
    cancelnewAttractionModal() {
      this.newAttractionModal = false;
    },

    // SETTING UP EDITITNG
    async handleStartAttractionEdit(attraction) {
      this.currentAttraction = attraction.id;
      try {
        const att = await this.fetchAttraction(this.currentAttraction);
        this.title = att.title;
        this.description = att.description;
        this.dateFrom = att.startDate ? new Date(att.startDate) : "";
        this.dateTo = att.endDate ? new Date(att.endDate) : "";
        this.chosenAttractionCategory = att.category.id;
        this.source = att.source || "";
        this.place = {
          address: att.address,
          street: att.street,
          zipCode: att.zipCode,
          country: att.country,
          city: att.city,
          latitude: att.lat,
          longitude: att.lng,
        };
        this.newAttractionModal = true;
        this.fetchedImages = (
          await Promise.allSettled(
            att.files.map((file) => this.getImage(file.id))
          )
        ).map((res) => res.value);
      } catch (e) {
        console.log(e);
      }
    },
    //TOGGLING VISIBLITY
    async handleToggleAttractionVisibility(id, visible) {
      if (!id) return;
      try {
        await this.toggleAttractionVisibility({ id, visible });
        await this.handleFetch();
        this.$toast.open({
          message: `Atrakcja będzie widoczna${
            visible
              ? " na stałe"
              : ", dopóki użytkownik posiada aktywną subskrypcję."
          }.`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono atrakcji`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },

    //ADD/EDIT ATTRACTION METHOD
    async saveNewAttraction() {
      if (
        this.title === "" ||
        this.description === "" ||
        this.chosenAttractionCategory === "" ||
        // this.dateFrom === "" ||
        // this.dateFrom?.toISOString() === "" ||
        // this.dateTo === "" ||
        // this.dateTo?.toISOString() === "" ||
        !this.place.city ||
        !this.place.zipCode ||
        !this.place.latitude ||
        !this.place.longitude
      ) {
        this.$toast.open({
          message: `Uzupełnij wszystkie pola: tytuł, opis, kategoria, adres (data od, data do opcjonalne)`,
          type: "error",
        });
      } else {
        if (this.dateTo < this.dateFrom) {
          this.$toast.open({
            message: `Data zakończenia nie może być wcześniejsza niż data rozpoczęcia`,
            type: "error",
          });
          return;
        }
        try {
          const body = {
            title: this.title,
            description: this.description,
            source: this.source || undefined,
            categoryId: this.chosenAttractionCategory,
            ...(this.dateFrom && this.dateFrom.toISOString()
              ? { startDate: this.dateFrom.toISOString() }
              : {}),
            ...(this.dateTo && this.dateTo.toISOString()
              ? { endDate: this.dateTo.toISOString() }
              : {}),
            street: this.place.street || undefined,
            address: this.place.address || undefined,
            city: this.place.city || undefined,
            zipCode: this.place.zipCode || undefined,
            country: this.place.country || undefined,
            lng: parseFloat(this.place.longitude) || undefined,
            lat: parseFloat(this.place.latitude) || undefined,
            filesToRemoveIds: this.imagesToDelete.length
              ? [...this.imagesToDelete]
              : undefined,
          };
          let res = {};
          if (this.currentAttraction === "") {
            res = await this.addAttraction(body);
          } else {
            body.id = this.currentAttraction;
            await this.editAttraction(body);
          }
          let imageResponses = [];
          if (res.data || this.currentAttraction) {
            imageResponses = await Promise.allSettled(
              this.attractionImagesToAdd.map((image) =>
                this.addImage({
                  file: image,
                  attractionId: res?.data?.id || this.currentAttraction,
                  type: "ATTRACTION_IMAGE",
                  visibleToAll: true,
                })
              )
            );
          }
          if (imageResponses.some((res) => res.value?.errorStatus)) {
            this.$toast.open({
              message: `Wystąpił z problem z przesłaniem zdjęcia: ${imageResponses.reduce(
                (total, res, index) =>
                  (total += `${res.value?.errorStatus ? ` ${index}` : ""}`),
                ""
              )}`,
              type: "error",
            });
          } else {
            this.$toast.open({
              message: `Atrakcja została ${
                this.currentAttraction === "" ? "dodana" : "edytowana"
              } pomyślnie.`,
              type: "success",
            });
          }
          this.cancelnewAttractionModal();
          this.currentAttraction = "";
          this.title = "";
          this.description = "";
          this.chosenAttractionCategory = "";
          this.dateFrom = "";
          this.dateTo = "";
          this.place = {};
          this.attractionImagesToAdd = [];
          this.attractionsImagesPreview = [];
          this.source = "";
          this.fetchedImages = [];
          this.imagesToDelete = [];
          await this.handleFetch();
        } catch (err) {
          if (err.status === 403) {
            this.$toast.open({
              message: `Operacja zabroniona`,
              type: "error",
            });
          } else if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono atrakcji`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      }
    },
    //DELETE ATTRACTION METHOD
    async handleDeleteAttraction() {
      if (this.currentAttraction === "") {
        this.$toast.open({
          message: `Wystąpił błąd, spróbuj ponownie.`,
          type: "error",
        });
      } else {
        try {
          await this.deleteAttraction(this.currentAttraction);
          this.$toast.open({
            message: `Atrakcja została usunięta`,
            type: "success",
          });
          this.handleCancel();
          await this.handleFetch();
        } catch (err) {
          if (err.status === 403) {
            this.$toast.open({
              message: `Operacja zabroniona`,
              type: "error",
            });
          } else if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono atrakcji`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      }
    },
    handleKeyPress() {},
  },
  async created() {
    const { searchAttraction: querysearchAttraction } =
      this.$router.history.current.query;
    this.searchAttraction = querysearchAttraction || "";
    try {
      await this.fetchAttractions({
        ...this.meta,
        searchAttraction: this.searchAttraction,
      });
      await this.fetchAttractionCategories();
    } catch (err) {
      if (err.status === 403) {
        this.$toast.open({
          message: `Operacja zabroniona`,
          type: "error",
        });
      } else if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono atrakcji`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },
  mounted() {
    this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    window.addEventListener("keypress", this.handleKeyPress);
    const searchAttractionFromQuery = this.$route.query.s;
    const newAttraction = this.$route.params.newAttraction;
    if (searchAttractionFromQuery) {
      this.attractionSearchText = searchAttractionFromQuery;
      if (newAttraction) {
        this.showNewAttractionModal();
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      allAttractions: "getAllAttractions",
      allAttractionsCategories: "getAllAttractionsCategories",
      meta: "getAttractionsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/variables.scss";
@import "/src/styles/select.scss";
@import "/src/styles/common-modal.scss";

strong {
  display: block;
}
p.info {
  max-width: 500px;
  overflow-wrap: break-word;
}
table {
  margin-top: 20px;
}
.notification-input-row {
  width: 100%;
  margin-top: 2rem;
}
.notification-input-row table {
  margin: 0 auto;
}
.notification-input-row label {
  margin-bottom: 2rem;
  width: 30%;
  font-weight: bold;
}
.notification-input-row input,
.notification-input-row textarea {
  width: 50%;
}
.found-users-table {
  margin-top: 2rem !important;
}
.found-users-table tbody tr {
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
}

.found-users-table tbody tr:hover {
  background: $primary;
  color: #fff;
}
.notifications-users {
  display: flex;
}
.notifications-users div:first-child,
.notifications-users div:nth-child(2) {
  width: 50%;
  margin: 0 auto;
}
.added-users {
  font-weight: bold;
  margin-top: 2rem;
}
.added-users-list p {
  margin: 0 !important;
  font-size: 15px;
}
@media screen and (max-width: 600px) {
  p.info {
    max-width: 300px;
  }
}
.vdp-datepicker {
  div {
    input {
      width: 100%;
      max-width: unset;
      min-width: 300px;
    }
  }
}
.datepicker-class {
  width: 100%;
  max-width: unset;
  min-width: 300px !important;
}
.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
}

.image-wrapper .delete-button {
  top: -5px;
  right: -5px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
