var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-content-wrapper"},[_c('ul',{ref:"chatContainer",staticClass:"rounded bg-light h-100 p-2 chat-content",style:({
      maxHeight: '57vh',
      overflow: 'auto',
      minHeight: '57vh',
      listStyle: 'none',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '10',
    })},[_c('div',{staticClass:"close-modal"},[_c('div',{staticStyle:{"width":"20px","height":"20px","border-radius":"100%","background":"red","color":"white","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v(" x ")])]),(_vm.messages.length > 0)?_c('div',_vm._l((_vm.messages),function(message,index){return _c('chat-message',{key:message.id,attrs:{"id":message.id,"message":message.text,"variant":_vm.variant(
            message.authorUser ? message.authorUser.id : message.authorUserId
          ),"user":message.authorUser
            ? message.authorUser.fullName
            : message.authorUserName || '-',"continued":_vm.continued(index),"sendDate":message.createdAt}})}),1):_c('div',[_c('p',[_vm._v("Brak wiadomości między użytkownikami")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }