import axios from "../../axios.js";

const addImage = async (_, data) => {
  const formData = new FormData();
  if (data.file) formData.append("file", data.file);
  if (data.attractionId) formData.append("attractionId", data.attractionId);
  if (data.type) formData.append("type", data.type);
  if (data.visibleToAll) formData.append("visibleToAll", data.visibleToAll);
  try {
    const res = await axios.post("cdn/upload", formData);
    return res;
  } catch (error) {
    return { errorStatus: error.response.status };
  }
};

const getImage = async (_, imageId) => {
  const url = `cdn/files/${imageId}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });
    return {
      imageId,
      file: URL.createObjectURL(response.data),
    };
  } catch (e) {
    return { errorStatus: e.response.status };
  }
};

const deleteImage = async (_, imageId) => {
  try {
    const res = await axios.delete(`cdn/delete/${imageId}`);
    return res;
  } catch (error) {
    return { errorStatus: error.response.status };
  }
};

export default {
  addImage,
  getImage,
  deleteImage,
};
