<template>
  <div class="container table-responsive">
    <div
      v-bind:class="
        openModal || showAcceptAllPaymentsModal ? 'd-flex' : 'd-none'
      "
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000020;
        z-index: 100;
        border-radius: 15px;
      "
    >
      <div v-if="showAcceptAllPaymentsModal" class="payments-modal">
        <p>Czy chcesz zatwierdzić wszystkie zaznaczone płatności?</p>
        <div class="d-flex">
          <b-button
            style="width: 75px; margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleAcceptAllPayments"
            >Tak
          </b-button>
          <b-button
            style="width: 75px; margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >anuluj
          </b-button>
        </div>
      </div>
      <div v-else class="payments-modal">
        {{
          `${
            modalAction === "REJECTED"
              ? "Czy chcesz odrzucić płatność?"
              : "Czy chcesz zatwierdzić płatność?"
          }`
        }}
        <div class="d-flex">
          <b-button
            style="width: 75px; margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleProcessPayment"
            >Tak
          </b-button>
          <b-button
            style="width: 75px; margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >anuluj
          </b-button>
        </div>
      </div>
    </div>
    <p class="h1 fw-light mb-2 mb-sm-4">Lista próśb o wypłaty</p>
    <div class="filter-wrapper">
      <h2>Filtr użytkowników</h2>
      <div class="filter-actions-wrapper">
        <input type="text" v-model="searchUser" />
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleUsersFilter"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
      <h3 style="margin-top: 20px">Filtr statusów</h3>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === '' }"
        @click="handleFetchWithStatus('')"
        >Wszystkie
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'WAITING' }"
        @click="handleFetchWithStatus('WAITING')"
        >Oczekujące
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'ACCEPTED' }"
        @click="handleFetchWithStatus('ACCEPTED')"
        >Zaakceptowane
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'REJECTED' }"
        @click="handleFetchWithStatus('REJECTED')"
        >Odrzucone
      </b-button>
    </div>
    <div :key="customKey">
      <p style="margin: 0">
        <strong>Ilość zaznaczonych elementów: </strong>
        <span>{{
          checkedValues.reduce((total, elem) => total + !!elem, 0)
        }}</span>
      </p>
    </div>
    <div
      class="d-flex"
      style="justify-content: center; gap: 10px; margin-bottom: 20px"
    >
      <b-button
        style="margin-top: 30px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        @click="handleExportPayouts"
        >Wyeksportuj dane do przelewów zbiorczych
      </b-button>
      <b-button
        style="margin-top: 30px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        @click="handleCheckAll"
        >Zaznacz wszystkie
      </b-button>
      <b-button
        style="margin-top: 30px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        @click="showAcceptAllPaymentsModal = true"
        >Zatwierdź wszystkie zaznaczone
      </b-button>
    </div>
    <table
      class="table align-middle"
      v-if="payments && payments.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody :key="customKey">
        <tr v-for="(payment, index) in payments" :key="payment.id">
          <td>
            {{ payment.user.payoutOwnerName || "-" }}
            <br />
            <b-button
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="
                $router.push({
                  name: 'Notifications',
                  query: { s: payment.user.phone },
                  params: { newNotification: '1' },
                })
              "
              >Wyślij powiadomienie</b-button
            >
          </td>
          <td>{{ payment.user.payoutAccountNumber || "-" }}</td>
          <td>
            {{ payment.createdAt ? `${modifyDate(payment.createdAt)}` : "-" }}
          </td>
          <td>
            {{ payment.modifiedAt ? `${modifyDate(payment.modifiedAt)}` : "-" }}
          </td>
          <td>
            {{
              payment.user.payoutStreet
                ? `${payment.user.payoutStreet || ""} ${
                    payment.user.payoutPostcode || ""
                  } ${payment.user.payoutCity || ""} ${
                    payment.user.payoutCountry || ""
                  }`
                : "-"
            }}
          </td>
          <td>
            {{
              `${
                payment.amountToPayout
                  ? `${payment.amountToPayout.toFixed(2)} zł`
                  : "-"
              }`
            }}
          </td>
          <td>{{ payment.status ? paymentStatuses[payment.status] : "-" }}</td>
          <td>
            <input
              type="checkbox"
              :checked="!!checkedValues[index]"
              v-on:change="handleCheckbox(payment.id, index)"
            />
            <div
              v-if="payment.status && payment.status === 'WAITING'"
              class="payment-actions"
            >
              <div class="hint-wrapper">
                <img
                  src="../assets/accept.png"
                  alt="Accept"
                  @click="handleShowModal(payment.id, 'ACCEPTED')"
                />
                <span class="hint" style="left: -10%">Zatwierdź płatność</span>
              </div>
              <div class="hint-wrapper">
                <img
                  src="../assets/decline.png"
                  alt="Decline"
                  @click="handleShowModal(payment.id, 'REJECTED')"
                />
                <span class="hint" style="left: -50%">Odrzuć płatność</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert
      v-else
      :content="'Brak próśb o wpłaty'"
    ></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchPayments`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :search-user="searchUser"
      @pageChanged="handleResetCheckboxes"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import { GENERAL_STATUSES } from "../utils/statuses";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import { parseDate } from "../utils/helpers";

export default {
  components: { Pagination, EmptyListAlert },
  name: "Payments",
  data: () => {
    return {
      columns: [
        "Imię i nazwisko użytkownika",
        "Numer konta",
        "Data utworzenia",
        "Data ostatniej modyfikacji",
        "Adres",
        "Kwota do wypłaty",
        "Status",
        "Akcje",
      ],
      checkedValues: new Array(20).fill(""),
      resetMeta: false,
      paymentStatuses: { ...GENERAL_STATUSES },
      actionId: "",
      openModal: false,
      showAcceptAllPaymentsModal: false,
      modalAction: "",
      searchUser: "",
      statusValue: "WAITING",
      customKey: new Date().getTime(),
      modifyDate: parseDate,
    };
  },

  methods: {
    ...mapActions(["fetchPayments", "processPayment", "exportPayouts"]),
    async handleFetch() {
      try {
        await this.fetchPayments({
          ...this.meta,
          searchUser: this.searchUser,
          status: this.statusValue,
          ...(this.resetMeta ? { current_page: 1 } : {}),
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleUsersFilter() {
      this.$router.push({
        name: "Payments",
        query: {
          status: this.statusValue,
          searchUser: this.searchUser,
        },
      });
      await this.handleFetch();
    },
    async handleFetchWithStatus(status) {
      this.statusValue = status;
      this.resetMeta = true;
      this.handleUsersFilter();
      //await this.handleFetch();
      this.handleResetCheckboxes();
      this.resetMeta = false;
    },
    handleReset() {
      this.searchUser = "";
      this.searchValue = "WAITING";
      this.$router.push({
        name: "Payments",
      });
      this.handleFetch();
      this.handleResetCheckboxes();
    },
    handleCheckbox(id, index) {
      if (this.checkedValues[index]) {
        this.checkedValues[index] = "";
      } else {
        this.checkedValues[index] = id;
      }
      this.customKey = new Date().getTime();
    },
    handleCheckAll() {
      if (this.checkedValues.slice(0, this.meta.total).every((e) => e !== "")) {
        this.checkedValues = new Array(20).fill("");
      } else {
        this.payments.forEach((payment, index) => {
          this.checkedValues[index] = payment.id;
        });
      }
      this.customKey = new Date().getTime();
    },
    handleCancel() {
      this.openModal = false;
      this.showAcceptAllPaymentsModal = false;
      this.actionId = "";
      this.modalAction = "";
    },
    handleResetCheckboxes() {
      this.checkedValues = new Array(20).fill("");
      this.customKey = new Date().getTime();
    },
    handleShowModal(id, status) {
      this.actionId = id;
      this.modalAction = status;
      this.openModal = true;
    },
    async handleProcessPayment() {
      try {
        await this.processPayment({
          payoutId: this.actionId,
          decision: this.modalAction,
        });
        await this.handleFetch();
        this.$toast.open({
          message: `Zatwierdzono płatność`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono wypłaty`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.openModal = false;
    },
    async handleAcceptAllPayments() {
      const onlyPendingCheckedPayments = this.payments.filter(
        (payment) =>
          payment.status === "WAITING" &&
          this.checkedValues.includes(payment.id)
      );
      if (onlyPendingCheckedPayments.length) {
        try {
          await Promise.allSettled(
            onlyPendingCheckedPayments.map((payment) =>
              this.processPayment({
                payoutId: payment.id,
                decision: "ACCEPTED",
              })
            )
          );
          await this.handleFetch();
          this.$toast.open({
            message: `Zatwierdzono wszystkie płatności`,
            type: "success",
          });
        } catch (err) {
          if (err.status === 404) {
            this.$toast.open({
              message: `Nie znaleziono wypłaty`,
              type: "error",
            });
          } else if (err.status === 500) {
            this.$toast.open({
              message: `Wystąpił błąd serwera`,
              type: "error",
            });
          }
        }
      } else {
        this.$toast.open({
          message: `Nie zaznaczono żadnej płatności`,
          type: "error",
        });
      }
    },
    async handleExportPayouts() {
      try {
        const filteredCheckedValues = this.checkedValues.filter(
          (e) => e !== ""
        );
        const response = await this.exportPayouts({
          ids: filteredCheckedValues,
        });
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "wyplata.csv";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        //this.checkedValues = [];
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono wypłaty`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleKeyPress(event) {
      const btn = document.querySelector("#filter-button");
      if (event.key === "Enter" && btn && this.$route.name !== "SqlReports") {
        btn.click();
      } else if (event.key === "Enter" && !btn) {
        this.$toast.open({
          message: `Wystąpił błąd`,
          type: "error",
        });
      }
    },
  },

  async created() {
    const { status: queryStatus, searchUser: querySearchUser } =
      this.$router.history.current.query;
    try {
      await this.fetchPayments({
        status: queryStatus || "WAITING",
        searchUser: querySearchUser || "",
        ...this.meta,
      });
      this.searchUser = querySearchUser || "";
      this.statusValue = queryStatus || "WAITING";
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 404) {
        this.$toast.open({
          message: `Nie znaleziono wypłaty`,
          type: "error",
        });
      } else if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },

  computed: {
    ...mapGetters({
      payments: "getAllPayments",
      meta: "getPaymentsMeta",
    }),
    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped>
.payment-actions {
  display: flex;
  gap: 5px;
}
.payment-actions img {
  cursor: pointer;
}

.hint-wrapper {
  position: relative;
}
.hint-wrapper img:hover + .hint {
  display: block;
}

.payments-modal {
  padding: 2rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
