import moment from "moment";

export default {
  formattedDate: function (date) {
    moment.locale("pl");
    return moment(date).format("MMMM Do YYYY, h:mm:ss a");
  },

  formattedDatePlFormatWithoutTime: function (date) {
    return moment(date).format("DD/MM/YYYY");
  },

  formattedDateAsNumbers: function (date) {
    moment.locale("pl");
    return moment(date).format("YYYY-MM-DD, HH:mm");
  },
  formattedDateWithoutTime: function (date) {
    moment.locale("pl");
    return moment(date).format("DD MMMM YYYY");
  },
  dateWithoutTimeToString: function (date) {
    moment.locale("pl");
    return moment(date).format("YYYY-MM-DD");
  },
  dateWithoutTimeToStringSeparatedWithSlash: function (date) {
    moment.locale("pl");
    return moment(date).format("YYYY/MM/DD");
  },

  startOfDayFromTimestampDate: function (date) {
    moment.locale("pl");
    return moment(date).startOf("day").toString();
  },
  endOfDayFromTimestampDate: function (date) {
    moment.locale("pl");
    return moment(date).endOf("day").toString();
  },
  isToday: function (date) {
    return moment(date).isSame(moment(), "day");
  },
  isFuture: function (date) {
    return moment(date).isAfter(moment(), "day");
  },
  formattedDateToString: function (date) {
    moment.locale("pl");
    return moment(date).format("LLL");
  },
  isValidDate: function (date) {
    return moment(date, "YYYY/MM/DD", true).isValid();
  },
  isValidEmail: function (email) {
    if (!email) return false;
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
};
