const state = {
  advertisementsForApproval: [],
  meta: {},
};

const mutations = {
  SET_ADVERTISEMENTS_FOR_APPROVAL(state, advertisementsForApproval) {
    state.advertisementsForApproval = advertisementsForApproval;
  },
  SET_ADVERTISEMENTS_FOR_APPROVAL_META(state, meta) {
    state.meta = meta;
  },
  DELETE_REMOVED_ADVERTISEMENT_FROM_LIST(state, removedAdvertisementId) {
    state.advertisementsForApproval = state.advertisementsForApproval.filter(
      (advertisement) => advertisement.id !== removedAdvertisementId
    );
  },
};

const getters = {
  getAllAdvertisementsForApproval: (state) => state.advertisementsForApproval,
  getAdvertisementsForApprovalMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
};
