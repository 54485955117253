import { render, staticRenderFns } from "./Attractions.vue?vue&type=template&id=46980395&scoped=true&"
import script from "./Attractions.vue?vue&type=script&lang=js&"
export * from "./Attractions.vue?vue&type=script&lang=js&"
import style0 from "./Attractions.vue?vue&type=style&index=0&id=46980395&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46980395",
  null
  
)

export default component.exports