const state = {
  reports: [],
  meta: {},
};

const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },

  SET_REPORTS_META(state, meta) {
    state.meta = meta;
  },
};

const getters = {
  getReports: (state) => state.reports,
  getReportsMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
};
