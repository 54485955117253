import axios from "../../axios.js";

const fetchAdvertisementsForApproval = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const search = "";
  const response = await axios.get(
    `advertisementsForApproval?${perPage}${pageNumber}${search}`
  );
  commit("SET_ADVERTISEMENTS_FOR_APPROVAL", response.data.data);
  commit("SET_ADVERTISEMENTS_FOR_APPROVAL_META", response.data.meta);
};

const removeAdvertisementForApproval = async ({ commit }, id) => {
  await axios.delete(`advertisementsForApproval/${id}`);
  commit("DELETE_REMOVED_ADVERTISEMENT_FROM_LIST", id);
};

const acceptAdvertisementForApproval = async ({ commit }, id) => {
  await axios.post(`advertisementsForApproval/${id}/accept`);
  commit("DELETE_REMOVED_ADVERTISEMENT_FROM_LIST", id);
};

const editAdvertisementForApproval = async (_, payload) => {
  await axios.put(`advertisementsForApproval/${payload.id}`, payload.data);
};

const addAdvertisementsForApproval = async ({ dispatch }, payload) => {
  await axios.post(`advertisementsForApproval/oferentAdvertisements`, {
    oferentToken: payload.oferentToken,
    urls: payload.urls,
    fromToday: payload.fromToday ? payload.fromToday : false,
  });
  await dispatch("fetchAdvertisementsForApproval", {});
};

export default {
  fetchAdvertisementsForApproval,
  removeAdvertisementForApproval,
  acceptAdvertisementForApproval,
  editAdvertisementForApproval,
  addAdvertisementsForApproval,
};
