const state = {
  documents: [],
  documentToEdit: null,
};

const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },

  SET_DOCUMENT_EDIT(state, document) {
    state.documentToEdit = document;
  },
};

const getters = {
  getAllDocuments: (state) => state.documents,
  getDocumentToEdit: (state) => state.documentToEdit,
};

export default {
  state,
  mutations,
  getters,
};
